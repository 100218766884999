import React, { FunctionComponent, useState } from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import CircularProgress from "@material-ui/core/CircularProgress"
import DialogTitle from "@material-ui/core/DialogTitle"
import SnackbarWrapper from "../../snackbar/SnackbarWrapper"
import lodash from "lodash"
import { useTranslation } from "react-i18next"
import { makeStyles, Theme, TextField } from "@material-ui/core"
import { useMutation, useQuery } from "@apollo/react-hooks"
import { ChannelType, CommunicationChannelType } from "../../../../api/graphql/generated/globalTypes"
import { SimpleAlertDialog } from "./SimpleAlertDialog"
import { toast } from "react-toastify"
import {
  ValidateCustomCommunicationResult,
  ValidateCustomCommunicationVariables,
  VALIDATE_CUSTOM_COMMUNICATION_QUERY,
} from "../../../../api/graphql/query/validate-custom-communication"
import {
  SendCustomMessageToChannelResult,
  SendCustomMessageToChannelVariables,
  SEND_CUSTOM_MESSAGE_TO_CHANNEL_MUTATION,
} from "../../../../api/graphql/mutation/send-custom-message-to-channel"

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    minWidth: 300,
  },
  textfield: {
    width: 400,
    borderWidth: 1,
    borderStyle: "dotted",
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(2),
  },
  input: {
    borderBottom: "0px solid",
  },
}))

interface ISendCustomMessageToChannelDialogProps {
  open: boolean
  onClose: () => void
  channelType: ChannelType.sms | ChannelType.email
  townId: string
}

export const SendCustomMessageToChannelDialog: FunctionComponent<ISendCustomMessageToChannelDialogProps> = (props) => {
  // Uncomment when used in future:
  // eslint-disable-next-line
  const classes = useStyles()
  const { t } = useTranslation()
  const { channelType, onClose, townId } = props

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>()
  const [successMessage, setSuccessMessage] = useState<string | null>()
  const [text, setText] = useState<string>("")

  const [sendCustomMessageMutation, { loading: sendMessageLoading }] = useMutation<
    SendCustomMessageToChannelResult,
    SendCustomMessageToChannelVariables
  >(SEND_CUSTOM_MESSAGE_TO_CHANNEL_MUTATION)

  const handleClose = () => {
    if (onClose) onClose()
  }

  const handleSendButton = () => {
    setIsLoading(true)
    sendCustomMessageMutation({
      variables: {
        channelType: channelType === ChannelType.sms ? CommunicationChannelType.SMS : CommunicationChannelType.EMAIL,
        message: text,
        receivers: validationObject?.receivers!!,
      },
    })
      .then((result) => {
        const resultData = result.data
        setSuccessMessage(
          t("sms_dialog.success_message_amount", {
            amount: resultData?.sendCustomMessageToChannel.sentToReceivers || 0,
          }),
        )
        if (resultData?.sendCustomMessageToChannel.errorCount) {
          setErrorMessage(resultData.sendCustomMessageToChannel.errorMessage)
        }
      })
      .catch((reason) => {
        setErrorMessage(lodash.get(reason, "data.sendToChannel.errorMessage", t("errors.unknown")))
        console.error(reason)
        toast.error(t("post_to_daheim_dialog.error"))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const { data: validationData } = useQuery<ValidateCustomCommunicationResult, ValidateCustomCommunicationVariables>(
    VALIDATE_CUSTOM_COMMUNICATION_QUERY,
    {
      variables: {
        channelType: channelType === ChannelType.sms ? CommunicationChannelType.SMS : CommunicationChannelType.EMAIL,
        townId,
      },
    },
  )
  const onAlertClose = () => {
    setSuccessMessage(null)
    onClose()
  }

  const validationObject = validationData?.validateCustomCommunication

  return (
    <Dialog open={props.open} onClose={handleClose} aria-labelledby={"sms_dialog_key"} className={classes.dialog}>
      <DialogTitle id={"sms_dialog_key"}>
        {channelType === ChannelType.sms ? t("sms_dialog.heading") : t("email_dialog.heading")}
      </DialogTitle>
      <DialogContent>
        {lodash.isString(errorMessage) && (
          <SnackbarWrapper variant="error" message={errorMessage} style={{ marginBottom: "12px" }} />
        )}
        {lodash.isString(successMessage) && (
          <SimpleAlertDialog
            isOpen={lodash.isString(successMessage)}
            onClose={onAlertClose}
            title={t("general.success")}
            message={successMessage}
          />
        )}
        <DialogContentText variant={"h5"}>{t("sms_dialog.receiver")}</DialogContentText>
        {lodash.isNil(validationObject) && <CircularProgress size={20} style={{ marginRight: "12px" }} />}
        {!lodash.isNil(validationObject) && (
          <div>
            <DialogContentText>
              {t("sms_dialog.amount_receiver")}
              {validationObject.receivers.length}
            </DialogContentText>
            <TextField
              multiline
              rows={5}
              className={classes.textfield}
              value={text}
              onChange={(event) => setText(event.target.value)}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={false} onClick={handleClose} color="primary">
          {t("general.cancel")}
        </Button>
        <Button variant="contained" disabled={isLoading} onClick={handleSendButton} color="primary">
          {isLoading && <CircularProgress size={20} style={{ marginRight: "12px" }} />}
          {t("general.send")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
