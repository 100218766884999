import { gql } from "apollo-boost"
import { sendTestMessageForWidgetToChannel, sendTestMessageForWidgetToChannelVariables } from "../generated/sendTestMessageForWidgetToChannel"

export const SEND_TEST_MESSAGE_FOR_WIDGET_TO_CHANNEL_MUTATION = gql`
  mutation sendTestMessageForWidgetToChannel(
    $widgetId: ID!
    $channelType: CommunicationChannelType!
    $communicationChannelReceiver: [String!]!
  ) {
    sendTestMessageForWidgetToChannel(
      widgetId: $widgetId
      channelType: $channelType
      communicationChannelReceiver: $communicationChannelReceiver
    ) {
      totalReceivers
      ratingLowReceivers
      ratingMediumReceivers
      ratingHighReceivers
      ratingUnknownReceivers
      sentToReceivers
      errorCount
      errorCode
      errorMessage
    }
  }
`


export type SendTestMessageForWidgetToChannelResult = sendTestMessageForWidgetToChannel
export type SendTestMessageForWidgetToChannelVariables = sendTestMessageForWidgetToChannelVariables