import React, { FunctionComponent, useState, useEffect, ChangeEvent, Fragment, useContext } from "react"
import {
  Theme,
  makeStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  DialogActions,
  Button,
  Fab,
  Divider,
  FormControlLabel,
  Checkbox,
  InputAdornment,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import lodash from "lodash"
import {
  AssociationInput,
  AssociationInputUpdate,
  ClassifierForTourTypeInput,
} from "../../../api/graphql/generated/globalTypes"
import {
  CREATE_ASSOCIATION_MUTATION,
  CreateAssociationResult,
  CreateAssociationVariables,
} from "../../../api/graphql/mutation/create-association"
import {
  UPDATE_ASSOCIATION_MUTATION,
  UpdateAssociationResult,
  UpdateAssociationVariables,
} from "../../../api/graphql/mutation/update-association"
import { useMutation } from "react-apollo"
import { ClipLoader } from "react-spinners"
import { DeleteForever } from "@material-ui/icons"
import {
  DeleteAssociationResult,
  DeleteAssociationVariables,
  DELETE_ASSOCIATION_MUTATION,
} from "../../../api/graphql/mutation/delete-association"
import { RatingSchemaControl } from "./rating-schema/RatingSchemaControl"
import { UserAndPermissionsAssociation } from "../../../api/graphql/query/permitted-associations-for-user"
import { convertValueToTextfieldType } from "../../../utils/convert-value-to-textfield-type"
import { TownForAssociation } from "../../../api/graphql/query/towns-for-association"
import { TourTypeClassifier } from "./TourTypeClassifier"
import { AuthContext } from "../../../context/AuthContext"
import { toast } from "react-toastify"
import { getGraphqlErrorCode } from "../../../utils/get-graphql-error-code"
import { AdminBadgeDivider } from "../badge/AdminBadgeDivider"
import { AssociationFractionMappingDialog } from "../fraction-mapping/AssociationFractionMappingDialog"
import { config } from "../../../utils/config"

const useStyles = makeStyles((theme: Theme) => ({
  dialogRoot: {
    minHeight: "200px",
    minWidth: "360px",
  },
  textFieldContainer: {
    margin: 0,
  },
}))

interface IManageAssociationDialogProps {
  association: UserAndPermissionsAssociation | undefined
  isOpen: boolean
  onCloseDialog: () => void
  onSuccess: () => void
  towns: TownForAssociation[] | undefined
  initialData: ClassifierForTourTypeInput[]
}

export const ManageAssociationDialog: FunctionComponent<IManageAssociationDialogProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { isOpen, onCloseDialog, onSuccess, association, towns, initialData } = props
  const [classifiers, setClassifiers] = useState<ClassifierForTourTypeInput[]>([])
  const [fractionMappingDialogOpen, setFractionMappingDialogOpen] = useState<boolean>(false)

  const { isAdmin } = useContext(AuthContext)

  useEffect(() => {
    setClassifiers(initialData)
  }, [initialData])

  const [createAssociationMutation, { loading: createAssociationMutationLoading }] = useMutation<
    CreateAssociationResult,
    CreateAssociationVariables
  >(CREATE_ASSOCIATION_MUTATION, {
    onError: (error) => {
      toast.error(t(`api_error.${getGraphqlErrorCode(error)}`, { entity: t("association.property.name") }))
      console.log("error: ", error)
    },
    onCompleted: (data) => {
      console.log("create success: ", data)
      onSuccess()
    },
  })

  const [updateAssociationMutation, { loading: updateAssociationMutationLoading }] = useMutation<
    UpdateAssociationResult,
    UpdateAssociationVariables
  >(UPDATE_ASSOCIATION_MUTATION, {
    onError: (error) => {
      toast.error(t(`api_error.${getGraphqlErrorCode(error)}`, { entity: association?.name || "-" }))
      console.log("error: ", error)
    },
    onCompleted: (data) => {
      console.log("update success: ", data)
      onSuccess()
    },
  })

  const [deleteAssociationMutation, { loading: deleteAssociationMutationLoading }] = useMutation<
    DeleteAssociationResult,
    DeleteAssociationVariables
  >(DELETE_ASSOCIATION_MUTATION, {
    onError: (error) => {
      console.log("error: ", error)
    },
    onCompleted: (data) => {
      console.log("delete success: ", data)
      onSuccess()
    },
  })

  const isInCreateMode = lodash.isNil(association)
  const isInEditMode = !isInCreateMode

  const associationAsInput = (association
    ? {
        name: association.name,
        documentDeletionRangeInDays:
          association.documentDeletionRangeInDays || config.REACT_APP_DEFAULT_DOCUMENT_DELETION_RANGE_IN_DAYS,
        location: {
          latitude: association.latitude,
          longitude: association.longitude,
        },
        ratingSchema: association.ratingSchema
          ? {
              residual: {
                percentageFrom: association.ratingSchema.residual.percentageFrom * 100,
                percentageTo: association.ratingSchema.residual.percentageTo * 100,
              },
              organic: {
                percentageFrom: association.ratingSchema.organic.percentageFrom * 100,
                percentageTo: association.ratingSchema.organic.percentageTo * 100,
              },
            }
          : {
              // if no specific RatingSchema is set: use 70% / 90% as Fallback
              // info: https://denovo-gmbh.atlassian.net/browse/WER-303
              residual: { percentageFrom: 70, percentageTo: 90 },
              organic: { percentageFrom: 70, percentageTo: 90 },
            },
        radius: association.radius,
        fractionMapping: association.fractionMapping,
      }
    : {
        radius: 5000,
      }) as AssociationInputUpdate

  const [modifiedAssociation, setModifiedAssociation] = useState<AssociationInputUpdate>(associationAsInput)

  const [isValid, setIsValid] = useState<boolean>(false)
  const [showDeleteForever, setShowDeleteForever] = useState<boolean>(false)
  const [showRatingSchema, setShowRatingSchema] = useState<boolean>(
    !lodash.isNil(lodash.get(association, "ratingSchema")),
  )

  useEffect(() => {
    if (
      lodash.isNil(modifiedAssociation.name) ||
      lodash.isNil(lodash.get(modifiedAssociation, "location.latitude")) ||
      lodash.isNil(lodash.get(modifiedAssociation, "location.longitude")) ||
      lodash.isNil(modifiedAssociation.radius) ||
      (Number(lodash.get(modifiedAssociation, "documentDeletionRangeInDays", -1)) <= 0 && isInEditMode)
    ) {
      setIsValid(false)
    } else {
      setIsValid(true)
    }
  }, [modifiedAssociation, isInEditMode])

  const updateModifiedAssociation = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const tempModifiedAssociation = { ...modifiedAssociation }
    const { name, type, value } = event.target
    lodash.set(tempModifiedAssociation, name, convertValueToTextfieldType(value, type))
    setModifiedAssociation(tempModifiedAssociation)
  }

  const onCreateAssociation = async () => {
    const { name, location, radius } = modifiedAssociation
    if (isValid) {
      console.log("new Association to create: ", modifiedAssociation)
      const associationAsInput = {
        name,
        location,
        radius,
        defaultClassifiers: classifiers,
        fractionMapping:
          (modifiedAssociation.fractionMapping || []).length > 0 ? modifiedAssociation.fractionMapping : null,
      } as AssociationInput

      await createAssociationMutation({
        variables: {
          association: associationAsInput,
        },
      })
    }
  }

  const onUpdateAssociation = async () => {
    const { name, location, radius, ratingSchema, documentDeletionRangeInDays } = modifiedAssociation
    if (isValid) {
      const associationInput: AssociationInputUpdate = {
        name,
        location,
        radius,
        documentDeletionRangeInDays: isAdmin ? Number(documentDeletionRangeInDays) : undefined,
        ratingSchema:
          showRatingSchema && ratingSchema
            ? {
                residual: {
                  percentageFrom: ratingSchema.residual.percentageFrom / 100,
                  percentageTo: ratingSchema.residual.percentageTo / 100,
                },
                organic: {
                  percentageFrom: ratingSchema.organic.percentageFrom / 100,
                  percentageTo: ratingSchema.organic.percentageTo / 100,
                },
              }
            : null,
        defaultClassifiers: classifiers,
        fractionMapping:
          (modifiedAssociation.fractionMapping || []).length > 0 ? modifiedAssociation.fractionMapping : null,
      }

      await updateAssociationMutation({
        variables: {
          id: lodash.get(association, "associationId", ""),
          association: associationInput,
        },
      })
    }
  }

  const onDeleteAssociation = async () => {
    if (lodash.isString(lodash.get(association, "associationId"))) {
      await deleteAssociationMutation({
        variables: { id: lodash.get(association, "associationId", "") },
      })
    }
  }

  // dont allow deletion when association has towns
  const disableDelete = !!(towns && towns.length > 0)

  return (
    <>
      <AssociationFractionMappingDialog
        open={fractionMappingDialogOpen}
        onChange={(fractionMapping) => setModifiedAssociation({ ...modifiedAssociation, fractionMapping })}
        onClose={() => setFractionMappingDialogOpen(false)}
        fractionMapping={modifiedAssociation?.fractionMapping || []}
        associationId={association?.associationId}
      />
      <Dialog
        open={isOpen}
        fullWidth={false}
        className={classes.dialogRoot}
        classes={{ paper: classes.dialogRoot }}
        onClose={onCloseDialog}
      >
        <form style={{ display: "contents" }}>
          <DialogTitle>
            {isInCreateMode
              ? t("association_management.dialog.create.title")
              : t("association_management.dialog.update.title")}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.textFieldContainer}>
                <TextField
                  name={"name"}
                  label={t("association.property.name")}
                  value={lodash.get(modifiedAssociation, "name", "")}
                  onChange={updateModifiedAssociation}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4} className={classes.textFieldContainer}>
                <TextField
                  name={"location.latitude"}
                  type="number"
                  label={t("association.property.latitude")}
                  value={lodash.get(modifiedAssociation, "location.latitude", "")}
                  onChange={updateModifiedAssociation}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4} className={classes.textFieldContainer}>
                <TextField
                  name={"location.longitude"}
                  type="number"
                  label={t("association.property.longitude")}
                  value={lodash.get(modifiedAssociation, "location.longitude", "")}
                  onChange={updateModifiedAssociation}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4} className={classes.textFieldContainer}>
                <TextField
                  name={"radius"}
                  type="number"
                  label={t("association.property.radius")}
                  value={lodash.get(modifiedAssociation, "radius", "")}
                  onChange={updateModifiedAssociation}
                  fullWidth
                />
              </Grid>

              {isAdmin() && (
                <>
                  <Grid item xs={12} className={classes.textFieldContainer}>
                    <AdminBadgeDivider />
                  </Grid>
                  <TourTypeClassifier
                    setClassifiers={setClassifiers}
                    initialClassifiers={initialData}
                    defaultClassifiers={[]}
                  />
                  <Grid item xs={6} className={classes.textFieldContainer}>
                    <TextField
                      name={"documentDeletionRangeInDays"}
                      type="number"
                      label={t("association.property.document_deletion_range_in_days")}
                      placeholder={`${config.REACT_APP_DEFAULT_DOCUMENT_DELETION_RANGE_IN_DAYS}`}
                      value={lodash.get(modifiedAssociation, "documentDeletionRangeInDays")}
                      onChange={updateModifiedAssociation}
                      fullWidth
                      InputProps={{
                        endAdornment: <InputAdornment position="end">{t("general.day", { count: 2 })}</InputAdornment>,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    className={classes.textFieldContainer}
                    container
                    justify="center"
                    alignContent="center"
                  >
                    <Button onClick={() => setFractionMappingDialogOpen(true)} color="primary" variant="contained">
                      {t("fraction_assignment.assign_fractions")}
                    </Button>
                  </Grid>
                </>
              )}
              {isInEditMode && (
                <Fragment>
                  <Grid item xs={12} className={classes.textFieldContainer}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} className={classes.textFieldContainer}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={showRatingSchema}
                          onChange={() => setShowRatingSchema(!showRatingSchema)}
                        />
                      }
                      label={t("association_management.enable_custom_rating_schema")}
                    />
                  </Grid>
                  {showRatingSchema && modifiedAssociation.ratingSchema && (
                    <Grid item xs={12} className={classes.textFieldContainer}>
                      <RatingSchemaControl
                        ratingSchema={modifiedAssociation.ratingSchema}
                        onChange={(newRatingSchema) =>
                          setModifiedAssociation({
                            ...modifiedAssociation,
                            ratingSchema: newRatingSchema,
                          })
                        }
                      />
                    </Grid>
                  )}
                </Fragment>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            {isInEditMode && showDeleteForever && (
              <Button
                color="primary"
                variant={"contained"}
                onClick={() => onDeleteAssociation()}
                disabled={
                  !lodash.isString(lodash.get(association, "id")) || deleteAssociationMutationLoading || disableDelete
                }
              >
                {t("association_management.dialog.delete.button")}
              </Button>
            )}
            <Button
              color="primary"
              onClick={() => (showDeleteForever ? setShowDeleteForever(false) : onCloseDialog())}
              disabled={
                deleteAssociationMutationLoading || createAssociationMutationLoading || updateAssociationMutationLoading
              }
            >
              {t("general.cancel")}
            </Button>
            {isInCreateMode && (
              <Button
                color="primary"
                variant={"contained"}
                onClick={() => onCreateAssociation()}
                disabled={!isValid || createAssociationMutationLoading}
              >
                {createAssociationMutationLoading ? (
                  <ClipLoader color={"white"} size={17} />
                ) : (
                  t("association_management.dialog.create.button")
                )}
              </Button>
            )}
            {isInEditMode && !showDeleteForever && (
              <Button
                color="primary"
                variant={"contained"}
                onClick={() => onUpdateAssociation()}
                disabled={!isValid || updateAssociationMutationLoading}
              >
                {t("association_management.dialog.update.button")}
              </Button>
            )}
            {isInEditMode && !showDeleteForever && (
              <Fab
                color={"primary"}
                size={"small"}
                onClick={() => setShowDeleteForever(true)}
                disabled={updateAssociationMutationLoading || disableDelete}
              >
                <DeleteForever />
              </Fab>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
