import { RfidChipType } from '../../api/graphql/generated/globalTypes';

export const sl = {
  admin: "Admin",
  denovo: "Denovo",
  language: "Jezik",
  request_timeout: "Omrežni dostop je trajal predolgo; Časovna omejitev omrežne zahteve je potekla",
  request_failed: "Dostop do omrežja ni uspel",
  daheim: "Daheim",
  symvaro: "Symvaro",
  languages: {
    de: "Deutsch",
    en: "English",
    sl: "Slovenščina",
  },
  tour_types: {
    organic: "Biološki odpadki",
    residual: "Ostali odpadki",
  },
  classifier: "Klasifikator",
  menu: {
    dashboard: "Nadzorna plošča",
    analysis: "Analiza rezultatov",
    pdf: "PDF izdelava",
    communication_center: "Komunikacijski center",
    tours: "Vozni red",
    association_management: "Združenje občin in občine",
    rfid_management: "RFID-ji",
    citizen_management: "Občan",
    user_management: "Uporabnik",
    login: "Prijava",
    text_management: "Besedila",
    vehicle_management: "Vozila",
    statistics: "Statistična ocena",
    eda: "EDA",
    log: "Log",
    file_templates: "Priloge",
    rfid_groups: "RFID skupine na hišo",
  },
  login_page: {
    email: "E-mail naslov",
    password: "Geslo",
    login: "Prijava",
    continue: "Naprej",
    data_privacy_statement: "Varstvo osebnih podatkov",
    imprint: "Impresum",
    error_title: "Navodila",
    forgot_password: "Pozabljeno geslo",
    password_reset_error: "Ponastavitev gesla ni uspela",
    password_reset_success: "E-mail z geslom je bil poslan na vaš E-Mail naslov",
    password_reset_dialog: {
      title: "Pozabljeno geslo",
      cancel: "Prekini",
      confirm: "Ponastavitev gesla",
    },
  },
  reports_page: {
    heading: "Poročila o rezultatih",
  },
  tour_page: {
    create_tour: "Ustvari vozni red/časovni plan",
    import_tours: "Uvozi uvozi časovne plane",
    heading: "Načrtovanje časovnega plana/voznega reda",
    errors: {
      duplicate: "Vozni red že obstaja",
      endtime_before_starttime: "Začetni čas mora biti pred končnim časom.",
    },
    warnings: {
      overlapping_entry_date:
        "Vozni red že obstaja s temi podatki: združenje občin, občina, frakcija, vozilo in datum.",
      overlapping_entry_date_time: "Čas voznega reda se prekriva s časom drugega voznega reda.",
    },
    import_rfid_readings: "Uvozi RFID podatkov/odčitavanja",
    import_tour_readings_dialog: {
      title: "Uvozi RFID branja",
      association_label: "Združenje",
      town_label: "Občina",
      import: "Uvozi",
      import_failed: "Napaka pri uvozu podatkov:",
      invalid_rfids: "Naslednji podatki niso bili registrirani v občino: '{{invalidRfids}}'",
      invalid_wastescanner_ids: "Naslednja vozila so neveljavna:'{{invalidWastescannerIds}}'",
      invalid_format: "Preverite GLAVO in podatke datoteke.",
      success:
        "Uspešen uvoz: Podatki so bili uvoženi in preskočeni???? Import erfolgreich: Es wurden {{countImported}} RFID Lesungen importiert und {{countDuplicates}} übersprungen (Duplikate)",
      close: "Zapri",
      daheim_info: "RFID branje je lahko uvoženo samo v Dom občino.",
    },
    filter: {
      has_analysis: "Podatki analize so na voljo",
      has_rfid_reader: "RFID branje je na voljo",
      has_data: "dostopno",
      doesnt_have_data: "ni na voljo",
    },
  },
  dashboard_page: {
    heading: "Nadzorna plošča",
    tour_section: {
      heading: "Vozni red",
      no_data: "V tem tednu ni poti!",
    },
    vehicle_section: {
      heading: "Tovornjaki",
      no_data: "Ni bilo najdenih tovornjakov!",
    },
  },
  analysis_page: {
    heading: "Rezultati analize skenerja za recikliranje",
    no_data_for_this_filter: "Ni najdenih podatkov za to nastavitev",
    filter_classification_default: "Standard",
    filter_classification_auto: "Samo samodejno",
    filter_classification_manually: "Samo ročno",
    composition_heading: "Zusammensetzung {{type}} für gefilterten Bereich",
    quality_of_seperation: "Kakovost ločevanja",
    pictures: "Slike",
    invalid_pictures: " Slike, ki jih ni mogoče razvrstiti",
    adjusted_date_to_emptying: "Datum prilagojen tednu praznenja",
    analysis_could_not_be_deleted: "Analize ni bilo mogoče izbrisati",
    filter: {
      association: "Združenje",
      town: "Občina",
      empty_week: "Teden praznjenja",
      empty_week_last_10_days: "lzadnjih 10 dni",
      waste_type: "Vrsta odpadkov",
      percent_of: "Odstotek od",
      percent_to: "Odstotek do",
      period_from: "Časovni okvir od",
      period_to: "Časovni okvir do",
      classification: "Klasifikacija",
      rating: "Vrednotenje",
      from: "Od",
      to: "Do",
      waste_scanner: "WasteScanner",
      source: "Izvor",
    },
    list: {
      classification: "Razvrstitev",
      bag_amount: "Vrečke",
      bag_amount_description: "je ocenjena kot razsute smeti",
    },
    classificationSelector: {
      ALL: "VSI",
      STANDARD: "Standard",
      MANUAL: "Samodejno",
      AUTOMATIC: "Ročno",
    },
    fractions: {
      miss_sortings: "napačen met",
      not_visible: "ni viden",
      Residual: "ostali odpadki",
      Organic: "biološki odpadki",
      Battery: "baterije",
      BlownBottle: "votlo telo",
      Glass: "steklo",
      Hazardous: "nevarni odpadki",
      Metal: "kovine",
      PET: "PET",
      Paper: "papir",
      Spraycan: "sprej",
      Plastic: "plastika",
      PlasticOther: "lahka embalaža",
      GarbageBagOther: "druga vreča",
      GarbageBagTransp: "prozorna vreča",
      GarbageBagYellow: "rumena vreča",
      GarbageBagOrganic: "razgradljiva vreča",
    },
    seperation_quality_history: "Kakovosti ločevanja v poteku",
    history: "V poteku",
    miss_sortings_quota: "Delež napačnih metov",
    tout_type_title_quota: "{{type}}delež",
    filter_tourtype_name: "Glavna frakcija",
    filter_tourtype_residual: "Ostali odpadki",
    filter_tourtype_organic: "Biološki odpadki",
    advancement_pdf: {
      section_title: "PDF izvoz",
      number_of_images: "Število slik: {{numberOfItems}}",
      disclaimer: "Za to nastavitev filtra je bilo najdenih {{numberOfItems}} slik.",
      terms_and_condition:
        "Osebna odgovornost uporabnika portala je spoštovanje obstoječih predpisov o varstvu podatkov.",
      gps_accuracy: "Pred nadaljevanjem bi radi opozorili, da je ločljivost GPS morda netočna.",
      button: {
        "0": "Ustvari",
        "1": "Se ustvarja...",
        "2": "Prenesi",
        "3": "Ni uspelo",
        menu: {
          create_zip: "Ustvari ZIP",
          create_pdf: "Ustvari PDF",
        },
      },
      tooltip_loading: "Prosimo za potrpljenje",
      ratings_error: "Izberite najmanj eno možnost",
      timespan_info: "Priporočljivo je, da kot časovni razpon izberete največ 2-3 tedne.",
      timespan_error: "Predlog časovnega razpona (max. 6 mesecov)",
    },
    waste_quality: {
      heading: "kakovost ločevanja odpadkov",
      num_of_invalid: "Število slik, ki jih ni mogoče razvrstiti: {{count}}",
    },
  },
  general: {
    show_more: "Pokaži več",
    main_fraction: "Glavne frakcije",
    waste: "Odpadki",
    send: "Pošlji",
    test: "Test",
    wastescanner: "Skener za recikliranje",
    wastescanner_portal: "Portal skenerja za recikliranje",
    logout: "Odjava",
    cancel: "Prekliči",
    data_loading: "Podatki se nalagajo...",
    details: "Podrobnosti",
    towns: "Občine",
    town: "Občina",
    latitude: "Geografska dolžina",
    longitude: "Geografska širina",
    radius: "Polmer",
    edit: "Uredi",
    delete: "Zbriši",
    remove: "Odstrani",
    create: "Ustvari",
    daheim_town: "Daheim/Dom občina",
    symvaro_town: "Symvaro občina",
    search: "Išči",
    reports: "Poročila",
    associations: "Združenja",
    associations_and_towns: "Združenja & Občine",
    no_data_available_reload_page: "Prišlo je do napake, prosim ponovno naložite stran",
    no_data_for_this_searchterm: "Za ta iskani izraz ni bilo najdenih podatkov",
    created_at: "Ustvarjeno na {{date}}",
    category: "Kategorija",
    rating: "Vrednotenje",
    loading: "Nalaganje",
    download: "Prenos",
    error: "Napaka",
    excel_export: "Excel izvoz",
    CW: "Koledarski teden",
    to: "do",
    convert: "Pretvorba",
    duplicate: "Duplikat",
    success: "Uspeh",
    save: "Shrani",
    confirm: "Potrdi",
    diagram: "Diagram",
    apply: "Uporabi",
    please_choose: "Prosim izberite...",
    no: "Ne",
    yes: "Ja",
    ok: "OK",
    manually: "ročno",
    custom: "Po meri uporabnika",
    default: "Standard",
    yes_delete: "Da, izbriši",
    list_loading: "Seznam se nalaga...",
    please_wait: "Prosimo za potrpljenje",
    to_few_data_diagram: "Ni dovolj podatkov za prikaz diagrama.",
    please_change_filter: "Prosimo, zamenjajte filter.",
    bookmark: "Zaznamek",
    bookmarks: "Zaznamki",
    name: "Ime",
    edited: "obdelano",
    channel: "Kanal",
    reupload: "Ponovno naloži",
    close: "zapri",
    all: "Vsi",
    trucks: "Tovornjaki",
    stats: "Statisika",
    clear: "Prekliči/Poenostavi filter",
    rating_schema: "Vzorec ocenjevanja",
    day: "Dan",
    day_plural: "Dnevi",
  },
  bookmark: {
    save_bookmark: "Shrani zaznamek",
    save_new_bookmark: "Ustvari nov zaznamek",
    delete_bookmark: "Izbriši zaznamek",
    create_bookmark: "Ustvari zaznamek",
    successfully_created: "Zaznamek je ustvarjen!",
    successfully_updated: "Zazmamek je shranjen!",
    no_bookmarks_found: "Nobeden zaznamek še ni bil ustvarjen!",
  },
  communication_channels: {
    daheim_app: "Daheim/Dom aplikaija",
    symvaro: "Symvaro",
    newspaper: "Časopis",
    black_board: "Oglasna deska",
    sms: "SMS",
    new_component: "Nova komponenta",
    choose_component: "Izberite komponento",
    email: "E-mail",
  },
  table: {
    no_data: "Podatki niso na voljo",
    previous: "Nazaj",
    next: "Naprej",
    page: "Stran",
    rows: "Vnosi",
    of: "od",
  },
  analysis_detail: {
    title: "Zajete frakcije",
    image_information: "Informacije o sliki",
    more: "Normalno",
    less: " Frakcije",
    invalid_image_title: "Neveljavna slika:",
    date_time: "Datum / Čas",
    image_number: "Številka slike",
    rfid_number: "RFID številka",
    delete_dialog: {
      heading: "Izbriši analizo",
      text: "Ali želite izbrisati to analizo?",
    },
  },
  tour: {
    types: {
      residual: "Ostali odpadki",
      organic: "Biološki odpadki",
    },
    property: {
      town: "Občina",
      towns: "Občine",
      association: "Združenje",
      tourType: "Frakcija",
      date: "Datum",
      startTime: "Začetek",
      endTime: "Konec",
      wasteScanner: "Wertstoffscanner",
      rfidReader: "RFID bralnik",
      geocept_device: "GeoCept naprava (IMEI)",
      analysis_data: "Analysedaten",
      is_splitted: "3 Bildanalyse",
      timePeriod: "Čas",
    },
    tableColumn: {
      analysisCount: "# Analiza",
      action: "Uredi",
    },
    dialog: {
      create: {
        title: "Ustvari nov časovni plan",
        button: "Ustvari",
      },
      create_anyway: {
        title: "Želite vseeno ustvariti vozni red?",
        button: "Vseeno ustvari",
      },
      update: {
        title: "Uredi časovni plan",
        button: "Shrani",
        time_info: "Vožnja se mora začeti pred {{min}} ali končati po {{max}}.",
      },
      update_anyway: {
        title: "Vseeno uredi časovni plan?",
        button: "Vseeno shrani",
      },
      delete: {
        button: "Trajno izbriši",
      },
      delete_tour_data: {
        title: "Izbriši RFID branje in analizo",
        title_deleted: "RFID branje in analize so bili izbrisani",
        text: "Ali ste prepričani, da želite izbrisati vse RFID branje za to voznjo?",
        text_deleted:
          "{{rfidReadingsCount}} RFID branje in {{materialAnalysisCount}} analiza(e) so bili uspešno izbrisani!",
        button: "Izbriši",
        delete_material_analysis_too: "Izbriši slike in analize",
      },
    },
  },
  validation: {
    time: {
      notpossible: "...Izbira/časa ni mogoča",
    },
    date: {
      notpossible: "...Datum ni mogoč",
    },
  },
  text_management: {
    heading: "Upravljanje besedila",
    add_category: "Kategorija",
    please_choose_category: "Prosim izberite kategorijo",
    add_template: "Dodaj predlogo besedila",
    no_templates_available: "Na voljno ni nobenih besedilnih predlog",
    freetext: "Prosto besedilo",
    placeholder: "Rezervirano mesto",
    text_editor: "Urejevalnik besedila",
    preview: "Predogled",
    default: "Standardna predloga",
    dialog: {
      create: {
        title: "Ustvari besedilno predlogo",
        button: "Ustvari",
      },
      update: {
        title: "Uredi besedilno predlogo",
        button: "Shrani",
      },
      delete: {
        heading: "Izbriši predlogo",
        text: "Ali želite izbrisati to predlogo?",
        confirmText: "Izbriši",
      },
    },
  },
  external_town_type: {
    daheim: "Daheim",
    symvaro: "Symvaro",
  },
  text_placeholder: {
    NEW_LINE_SINGLE: "Nova vrstica",
    NEW_LINE_DOUBLE: "Prazna vrstica",
    TOUR_TYPE: "Glavna frakcija",
    TOWN_NAME: "Občina",
    MAIN_MISSTHROW_1_NAME: "1. Glavni material za napačno odmetavanje",
    MAIN_MISSTHROW_2_NAME: "2. Glavni material za napačno odmetavanje",
    MISSTHROW_PERCENTAGE: "Odstotek napačnih metov",
    MISSTHROW_PER_TOWN_PERCENTAGE: "Odstotek napačnih metov (občina)",
    RFID: "RFID(ji)",
    MATERIAL_ANALYSIS_LIST: "Praznjenje (seznam)",
    INVALID_TOTAL_COUNT: "Število nerazvršečnih",
    INVALID_REASON: "Razlog za nerazvrstitev",
    preview: {
      NEW_LINE_SINGLE: "\n",
      NEW_LINE_DOUBLE: "\n\n",
      TOUR_TYPE: "Ostali odpadki",
      TOWN_NAME: "Feldkirchen",
      MAIN_MISSTHROW_1_NAME: "Plastika",
      MAIN_MISSTHROW_2_NAME: "Steklo",
      MISSTHROW_PERCENTAGE: {
        High: "1",
        Medium: "14",
        Low: "50",
        Unknown: "14",
      },
      RFID: "303030303",
      MATERIAL_ANALYSIS_LIST:
        "Vorčna ulica 3, Vzorčno mesto 0000 (12.12.2022, 12:12:12)\nVzorčna ulica 6, Vzorčno mesto 0000 (12.12.2022, 12:15:15)",
      MISSTHROW_PER_TOWN_PERCENTAGE: "14",
      INVALID_TOTAL_COUNT: "5",
      INVALID_REASON: "Samo čokolada je bila v smeteh",
    },
  },
  association_management: {
    heading: "Uprava združenja",
    add_association: "Dodaj združenje",
    add_town: "Dodaj občino",
    no_towns: "Ni občine za to združenje",
    enable_custom_rating_schema: "Ročno nastavite shemo vrednotenja analize",
    dialog: {
      create: {
        title: "Ustvari združenje",
        button: "Ustvari",
      },
      update: {
        title: "Uredi združenje",
        button: "Shrani",
      },
      delete: {
        button: "Trajno izbriši",
      },
    },
    town_dialog: {
      create: {
        title: "Ustvari občino",
        button: "Ustvari",
      },
      update: {
        title: "Uredi občino",
        button: "Shrani",
      },
      delete: {
        button: "Trajno izbriši",
      },
      link_with_town_boundaries: "Povezava z Geofence",
      link_with_town_boundaries_info: "Pri nalaganju analiz so te samodejno povezane z občino.",
      no_linked_town_linked: "K povezavi ni bila izbrana občina",
      successfully_linked_with_town: "Povezava do {{townName}}",
      create_as_external_town: "Povezava z zunanjimi občinami",
      only_manually_analysis: "Pokaži uporabnikom samo ročno razvrščene podatke",
      label_info: "Kot skrbnik si lahko ogledate vse podatke.",
    },
  },
  citizen_management: {
    heading: "Upravnik po občinah",
    no_citizens: "V tej občini ni prebivalcev.",
    dialog: {
      update: {
        title: "Uredi občana",
        button: "Shrani",
      },
      delete: {
        button: "Trajno izbriši",
      },
    },
    import_citizens: "Uvozi občana",
    import_citizens_dialog: {
      title: "Uvozi občana",
      association_label: "Združenje",
      town_label: "Občina",
      import: "Uvoz",
      close: "Zapri",
      success: "Občana ste uspešno uvozili.",
      unknown_error: "Prišlo je do neznane napake.",
      daheim_info: "Uvozi se lahko samo občan Dom občine.",
    },
    export_citizens: "Izvoz občana",
    export_citizens_dialog: {
      title: "Izvoz občana",
      association_label: "Združenje",
      town_label: "Občina",
      export: "Izvoz",
      close: "Zapri",
      success: "Občana ste uspešno izvozili.",
      unknown_error: "Občana ste uspešno izvozili.",
      daheim_info: "Izvozi se lahko samo občan Dom občine.",
    },
  },
  user_management: {
    heading: "Samostojni uporabnik",
    add_user: "Dodaj uporabnika",
    associations_and_towns: "Združenje in občine",
    vehicles: "Vozila",
    assign_all_towns: "dodeli vse občine",
    all_towns_assigned: "vse občine",
    select_single_towns_info: "Občine lahko dodelite tudi posamično",
    set_town_as_default: "Določite kot domačo občino - Pri vstopu v portal bo domača občina izbrana za analizo.",
    dialog: {
      create: {
        title: "Ustvari uporabnika",
        button: "Ustvari",
      },
      update: {
        title: "Uredi uporabnika",
        button: "Shrani",
      },
      delete: {
        button: "Trajno izbriši",
      },
      info_password_set: "Geslo je že nastavljeno.",
      info_password_not_set: "Geslo še ni nastavljeno.",
      link_reset_password: "Na E-Mail vam je bilo poslano sporočilo",
    },
    ability: {
      admin: "Admin",
      manager: "Upravitelj",
      tugraz: "TU Graz",
      area_manager: "Licenčni admin",
      advancement_pdf: "PDF za ocenjevanje",
      town_boundaries: "Geofence",
      waste_composition: "Missthrow Composition", // TODO: get correct translation
    },
  },
  town: {
    property: {
      name: "Ime občine",
      latitude: "Geografska dolžina",
      longitude: "Geografska širina",
      radius: "Polmer",
      zipCode: "Poštna številka",
      classification: "Klasifikacija",
      classification_auto: "samodejno",
      classification_manually: "ročno",
      classification_unset: "-",
    },
  },
  association: {
    property: {
      name: "Združenje",
      latitude: "Geografska dolžina",
      longitude: "Geografska širina",
      radius: "Polmer",
      document_deletion_range_in_days: "Dokument izbriši po",
    },
  },
  citizen: {
    property: {
      firstName: "Ime",
      lastName: "Priimek",
      rfid: "RFID {{number}}",
      rfids: "RFID-ji",
      code: "Koda",
      address: "Naslov",
      phone1: "Telefonska št. 1",
      phone2: "Telefonska št. 2.",
      email: "E-mail naslov",
    },
  },
  user: {
    property: {
      email: "E-mail",
      name: "Ime",
      towns: "Občina",
      associations: "Združenje",
      abilities: "Vloga/funkcija",
      default_town: "Domača občina",
    },
  },
  communication_center: {
    heading: "Komunikacijski center",
    no_reports: "Ni bilo najdenih poročil.",
    overall_rating: "Povprečna ocena",
    create_a_report_from_an_analysis: "Ustvarite prvo poročilo iz analize.",
    text_individual_widget: {
      dialog: {
        heading: "Individuelno besedilo",
        text_category: "Besedilna kategorija",
      },
    },
    last_sent: "zadnje poslano",
    no_history_entry: "še ni poslano",
    last_post_failed: "Med zadnjim poskusom pošiljanja je prišlo do napake.",
  },
  post_to_daheim_dialog: {
    heading: "Pošlji najnovejše stanje {{externalTownType}}",
    error: "Prišlo je do napake, poročila ni bilo mogoče objaviti.",
  },
  password_reset: {
    heading: "Ponastavitev gesla",
    new_password: "Novo geslo",
    new_password_again: "Potrdite novo geslo",
    set_password: "Nastavite novo geslo",
    back_to: "Nazaj",
    login: "Prijava",
  },
  api_error: {
    "1002": "Vožnja se prekriva z drugo vožnjo!",
    "1011": "Shema ocenjevanja ni veljavna",
    "1020": "Komunikacijskega kanala ni bilo mogoče preveriti.",
    "2001": "Naslov je predolg (največ 200 znakov)",
    "3001": "Povezava ni več veljavna!",
    "7601": "SMS-a ni bilo mogoče poslati. Preverite telefonsko številko",
    "7603": "Email-a ni bilo mogoče poslati. Preverite elektronski naslov.",
    "8102": "{{entity}} že obstaja!",
    "8202": "{{entity}} ne obstaja!",
    "65616": "Ta RFID čip ne obstaja.",
    "65622": "Nimate dovoljenja za to dejanje.",
  },
  WER_ERROR_CODE: {
    SUCCESS: "Uspeh",
    OTHER: "Ne poznana napaka",
    ABILITY_MISSING: "Manjkajoče pravice",
    SIGN_IN__EMAIL_UNKNOWN: "E-mail naslov je napačen.",
    SIGN_IN__WRONG_PASSWORD: "Geslo je napačno.",
    USER__INVALID_INPUT: "Vnosi so napačni",
    USER__EMAIL_EXISTS: "Ta E-mail že obstaja",
  },
  waste_quality: {
    Unknown: "Ni možno razvrstiti",
    Low: "Slabo",
    Medium: "Srednje",
    High: "Dobro",
  },
  waste_quality_short: {
    Unknown: "U",
    Low: "S",
    Medium: "M",
    High: "G",
  },
  report: {
    create_report: "Ustvarite poročilo",
    description_title: "Dodajte poročilu naslov, da bo kasneje lažje najden.",
    title: "?Naslov?",
    delete: "Izbrišite poročilo",
    delete_description: "Ali ste prepričani, da želite izbrisati poročilo {{report}}?",
    free_text: "Prosto besedilo",
    enter_free_text: "Vnos prostega besedila",
    overall_rating: "Povprečna ocena",
    widget_not_recognised: "Neznan gradnik",
    transform_graph: "Spremeni diagram",
  },
  widget_type: {
    title_text: "Besedilo",
    generic_title_text: "Naslov",
    title_pie_chart: "Tortni graf",
    title_pie_chart_generic: "Graf",
    title_pie_chart_waste_quality: "Tortni graf s kakovostjo ločevanja odpadkov",
    title_pie_chart_waste_quality_generic: "Graf",
    title_history_chart: "Diagram poteka",
    title_history_chart_generic: "Graf",
    title_trend_chart: "Graf ločevanja za občino",
    title_trend_chart_generic: "Graf",
    title_chart_trend_chart_comparison: "Graf ločevanja",
    title_trend_chart_comparison_generic: "Graf",
    title_individual_text: "Individualno besedilo",
    choose_text_title: "Izberite besedilo",
    choose_text_info: "Prosim, izberite besedilo.",
  },
  graph: {
    percent: "%- delež",
    trend: {
      heading: "Trenddiagramm Gemeinde ({{townName}})",
      tooltip: {
        max: "Maximum",
        min: "Minimum",
        trend: "Trend",
        overall: "Povprečno",
      },
    },
    comparison_trend: {
      heading: "Graf ločevanja za združenje({{associationName}})",
      tooltip: {
        max: "Maximum",
        min: "Minimum",
        trend: "Trend",
        overall: "Povprečno",
      },
      select_towns: "Izberite vsaj eno občino. Istočasno lahko izberete največ tri skupnosti.",
    },
  },
  sms_dialog: {
    heading: "Pošlji SMS",
    receiver: "Prejemnik",
    when_send: "Kdaj želite poslati SMS?",
    date: "Datum",
    time: "Čas",
    warning_select_every_lane: "Prosimo, izberite eno besedilo v vsakem stolpcu!",
    amount_receiver: "Število prejemnikov: {{amount}}",
    good_news_amount: "Dobra sporočila:{{amount}}",
    medium_news_amount: "Srednja sporočila: {{amount}}",
    bad_news_amount: "Slaba sporočila: {{amount}}",
    general_news_amount: "Splošna sporočila: {{amount}}",
    success_message_amount: "{{amount}} sporočil(a) je bilo poslano.",
    success_message: "Sporočilo je bilo poslano.",
    test_info_text: "Izbrano besedilo bo poslano nespremenjeno na naslednjo telefonsko številko.",
    test_info_texts: "Obrazci bodo z naključnimi podatki poslani na naslednjo telefonsko številko.",
    rfid: "RFID",
    rfid_group: "RFID skupine",
  },
  email_dialog: {
    heading: "Pošlji E-mail",
    receiver: "Prejemnik",
    when_send: "Kdaj želite poslati E-Mail?",
    date: "Datum",
    time: "Čas",
    warning_select_every_lane: "Prosimo, izberite besedilo v vsakem stolpcu!",
    amount_receiver: "Število prejemnikov: {{amount}}",
    good_news_amount: "Dobra sporočila:{{amount}}",
    medium_news_amount: "Srednja sporočila: {{amount}}",
    bad_news_amount: "Slaba sporočila: {{amount}}",
    general_news_amount: "Splošna sporočila: {{amount}}",
    success_message_amount: "{{amount}} sporočil(a) je bilo poslano.",
    success_message: "Sporočilo je bilo poslano.",
    test_info_text: "Izbrano besedilo bo poslano nespremenjeno na naslednje elektronske naslove.",
    test_info_texts: "Obrazci bodo z naključnimi podatki poslani na naslednje elektronske naslove.",
    rfid: "RFID",
    rfid_group: "RFID skupine",
  },
  excel_export: {
    excel_export: "Izvoz v Excelu",
    filter: "Filter",
    no_filter_set: "Ni nastavljenih filtrov",
    main_fraction: "Glavna frakcija",
    wrong_fractions: "Napačne frakcije",
    address_unknown: "Neznano",
    export_unmapped: "izvoz z vsemi frakcijami",
    export_mapped: "izvoz z združenimi frakcijami",
    export: {
      organization: "Organizacija",
      overall_rating: "Povprečna ocena",
      wertstoff: "Wertstoff",
      amount: "Delež",
      missthrow: "Napačno odvrženo",
      date: "Datum",
      gps: "GPS",
      classification: "Klasifikacija",
      total_miss_sortings: "Skupno število napačno odloženega",
      address: "Naslov",
      rfid: "RFID",
      file: "Ime datoteke",
      rating: "Vrednotenje",
      invalid: "Neveljavno",
    },
  },
  errors: {
    unknown: "Nepoznana napaka",
    generic1: "Ups, prišlo je do napake!",
    generic2: "To se ne bi smelo zgoditi!",
    no_reports: "Brez poročil",
    no_reports2: "Ustvarite prvo poročilo iz analize.",
    endtime_before_starttime: "Začetni čas mora biti pred končnim časom.",
  },
  import_info: {
    error_types: {
      HEADER_INCORRECT: "Prosim, preverite glavo (prvo vrstico) Excelove datoteke",
      TIME_SPAN_INCORRECT: "Vrstica {{line}}: Preverite ure ({{date}} {{time1}}-{{time2}})",
      TIME_FORMAT_INCORRECT: "Vrstica {{line}}: preverite datum/čas ({{date}}, {{time1}}, {{time2}})",
      ASSOCIATION_NOT_FOUND: "Vrstica {{line}}: Občine ({{asociation}}) ni bilo mogoče najti",
      TOWN_NOT_FOUND: "Vrstica {{line}}: Združenja ({{town}}) ni bilo mogoče najti",
      FRACTION_NOT_FOUND: "Vrstica {{line}}: frakcije ({{fraction}}) ni bilo mogoče najti",
      DUPLICATE: "Vrstica {{line}}: zapis že obstaja",
      OVERLAPPING_ENTRY_1: "Vrstica {{line}}: zapis se prekriva z vnosom v bazi podatkov",
      OVERLAPPING_ENTRY_2: "Vrstica {{line}}: zapis se prekriva z vnosom v vrstici {{line2}}",
      ENTRY_INCOMPLETE: "Vrstica {{line}}: vsebuje prazne stolpce",
      WASTESCANNER_NOT_FOUND: "Vrstica {{line}}: Optični bralnik odpadkov ({{wastescanner}}) ni najden",
      RFID_NOT_MATCHING_TOWN: "Vrstica {{line}}: RFID ({{rfid}}) ni registriran v izbrani občini",
      RFID_NOT_FOUND: "Vrstica {{line}}: RFID ({{rfid}}) ni bil najden",
      CODE_FOR_RFID_UPDATED:
        'Vrstica {{line}}: Koda za RFID ({{rfid}}) je bila posodobljena: "{{originalCode}}" --> "{{newCode}}"',
      BOOLEAN_VALUE_INCORRECT: "Vrstica {{line}}: Je neveljavna! (Ja/Nein)",
      RFID_ALREADY_EXISTS: "Vrstica{{line}}: RFID že obstajajo in se pri uvozu prepišejo",
      NO_ACCESS_TO_VEHICLE: "Vrstica{{line}}: Nimate dostopa do tega vozila. ({{wasteScannerId}})",
      PREFERRED_COMMUNICATION_CHANNEL_VALUE_INCORRECT:
        'Vrstica {{line}}: Vrednost prednostnega komunikacijskega kanala je neveljavna! (SMS/E-Mail ali oboje z  "," loči)',
    },
    warning_types: {
      UNKNOWN_RFID_CHIP_TYPE: 'Line {{line}}: Container type ({{type}}) is invalid. Type "Other" will be used.',
    },
  },
  import_tours_dialog: {
    error_file_selector: "Izberite Excel z maksimalno velikostjo 2MB,",
    move_files: "Datoteko prikaži tukaj ali",
    drop_files: "",
    select_files: "klikni za izbor",
    import_success_heading: "Uvoz uspešen",
    success: "Poti {{number}} so bile uspešno uvožene.",
    error: "Pri uvozu je prišlo do napake.",
    import: "Uvozi",
    import_info:
      "Pred uvozom navedi vozila in pazi, da je številka RFIDi čitalnika navedena,če obstaja, da ta številka ne bo automatsko prenesena iz podatkov o vozilu.",
    import_nevertheless: "Vseeno uvozi",
    file_rejected: "Datoteka ni možna v tem formatu.",
  },
  widget_text_categories: {
    rating_with_missthrow_percentage: "Vrednotenje z deležem napak",
    rating_with_main_missthrow: "Vrednotenje z glavnim kontaminentom",
    black_board: "Oglasna deska",
    compare_with_town: "Primerjava trenutnega filtra s celotno občino",
  },
  vehicle_management: {
    heading: "Upravljanje vozil",
    create_vehicle: "Ustvari vozilo",
    dialog: {
      create: {
        title: "Ustvari vozilo",
        button: "Ustvari",
      },
      update: {
        title: "Uredi vozilo",
        button: "Shrani",
      },
      delete: {
        button: "Trajno izbriši",
      },
    },
  },
  vehicle: {
    property: {
      wasteScannerId: "Skener za recikliranje",
      rfidReader: "RFID bralnik",
      number_of_tours: "Število voženj",
      geocept_device: "GeoCept naprava (IMEI)",
      rfidReadingToMaterialAnalysisOffset: {
        min: "min latenca RFID bralnika - analiza",
        max: "max latenca RFID bralnika - analiza",
      },
      geoCeptToMaterialAnalysisOffset: {
        min: "min latenca GeoCept - analiza",
        max: "max latenca GeoCept - analiza",
      },
      image_usage_info: "{{used}} von {{available}}",
      image_usage: "poraba slik",
      image_limit: "omejitev slik",
    },
  },
  material_analysis: {
    invalidity_type: {
      GENERAL: "Prišlo je do druge napake ({{reason}})",
      BAG_AMOUNT: "Delež vreč za smeti je previsok: >66 %",
      BRIGHTNESS: "Preveč je svetlo/temno",
      CURTAIN: "?Der Vorhang verdeckt die Wertstoffe?",
      DUST: "Preveliko prahu",
      PRESS: "Na sliki je preša",
      NOT_INVALID: "ni problema",
      SPLIT_NOT_VALIDATED: "Analiza še ni preverjena.",
    },
  },
  statistics: {
    heading: "Wertstoffscanner Statistikauswertung",
    invalid_images_title: "neveljavna slika",
    overview: {
      text: {
        total: "{{total}} zajete slike",
        valid: "{{valid}} vrednotene slike",
        invalid: "{{invalid}} ne vrednotene slike",
        refine_results: "Izpolnite rezultate",
      },
    },
    excel_export: {
      file_name: "Wertstoffscanner Statistik",
    },
    diagram: {
      title: "Diagramm",
      total: "zajete",
      valid: "vrednotene",
      invalid: "ne vrednotene",
    },
  },
  frequency: {
    daily: "Dnevno",
    weekly: "Tedensko",
    monthly: "Mesečno",
  },
  pdf_page: {
    heading: "Ustvari PDF",
  },
  eda_page: {
    heading: "Event Driven Architecture",
    approximateNumberOfMessages_tooltip: "Trenutno je v čakalni vrsti en Job.",
    approximateNumberOfMessages_tooltip_plural: "Trenutno je v čakalni vrsti {{count}} Jobs.",
    approximateNumberOfMessagesNotVisible_tooltip: "Job je trenutno v obdelavi",
    approximateNumberOfMessagesNotVisible_tooltip_plural: "Trenutno je {{count}} Jobs v obdelavi",
    delaySeconds_tooltip: "Job čaka v čakalni vrsti približno {{count}} sekund, preden je obdelan.",
    delaySeconds_tooltip_plural: "Job čaka v čakalni vrsti približno {{count}} sekund, preden je obdelan.",
  },
  file_template_page: {
    heading: "Predloge datotek",
  },
  rfid_management: {
    heading: "Upravljanje RFID",
    import_rfids: "Uvozi RFID-je",
    export_rfids: "Izvozi RFID-je",
    no_rfids: "RFID ni na voljo",
    dialog: {
      update: {
        title: "Uredi RFID",
      },
      delete: {
        title: "Izbriši RFID",
        content: "Ali ste prepričani?",
      },
    },
    import_dialog: {
      title: "Uvozi RFID-je",
      close: "Zapri",
      import: "Uvozi",
      success: "RFID-ji so bili uspešno uvoženi!",
    },
    export_dialog: {
      title: "Izvozi RFID-je",
      close: "Zapri",
      export: "Izvozi",
      success: "RFID-ji so bili uspešno izvoženi!",
      association_label: "Združenje",
      town_label: "Občina",
    },
    unassignable_addresses: {
      resolve: "Beheben",
      info: "V tej skupnosti je {{numUnassignableAddresses}} nedodeljenih naslovov.",
      dialog: {
        title: "Nedodeljeni naslovi",
        export_addresses: "Izvozi nedodeljene naslove",
      },
    },
    delete_error: {
      EXISTING_ANALYSIS: "RFID ni mogoče izbrisati. Analiza materiala že obstaja!",
      EXISTING_CITIZENS_CONNECTION: "RFID ni mogoče izbrisati. Povezava z občani že obstaja!",
      EXISTING_RFID_READING: "RFID ni mogoče izbrisati. RFID odčitavanje že obstaja!",
    },
  },
  unassignable_address: {
    property: {
      address: "Naslov",
      comment: "Komentar",
    },
  },
  rfid: {
    property: {
      rfid: "RFID",
      address: "Naslov",
      code: "Koda",
      uniquely_assignable: "Jasno dodeliti",
      citizens: "Občan",
      type: "Container Type", // TODO: add translation
      comment: "Komentar",
      types: {
        [RfidChipType.SA1100L]: "SA1100L",
        [RfidChipType.SA120L]: "SA120L",
        [RfidChipType.SA240L]: "SA240L",
        [RfidChipType.SA360L]: "SA360L",
        [RfidChipType.SA770L]: "SA770L",
        [RfidChipType.SA80L]: "SA80L",
        [RfidChipType.Sonstiges]: "Other{{comment}}", // TODO: add translation
      },
    },
  },
  rfid_groups: {
    heading: "RFID skupina po hiši",
    dialog: {
      create: {
        title: "Ustvari RFID skupino",
        create_button: "Ustvari skupino",
        rfid_field: "Dodaj RFID",
        undefined_address_info: "Ob vnestitvi veljavnega naslova, lahko dodate RFID v svojo skupino.",
        invalid_address_info: "RFID skupina za naveden naslov že obstaha!",
      },
      update: {
        title: "Uredi RFID skupino",
        update_button: "Posodobi skupino",
      },
      delete: {
        title: "Izbriši RFID skupino",
        content: "Ali želite resnično izbrisati RFID skupino?",
      },
      create_default: {
        title: "Automatische RFID Gruppen erstellen",
        content: "Ali res želite ustvariti RFID skupino na hišo z več kot enim RFID-jem?",
        no_new_groups: "Za to občino ni bila ustvarjena nobena nova RFID skupina.",
      },
      rfid_collection_title: "Skupine RFID-jev",
    },
    add_rfid_group_button: "Dodaj RFID skupine",
    create_default_rfid_groups_button: "Automatische RFID Gruppen hinzufügen",
    property: {
      address: "Naslov",
      rfids: "RFID-ji",
      street: "Ulica",
      streetNumber: "Hišna št.",
      city: "Mesto",
      postalcode: "Poštna št.",
    },
    no_rfidgroups: "Na voljo ni nobena RFID skupina.",
  },
  material_analysis_source: {
    geocept: "GeoCept",
    rfid: "RFID",
    other: "Drugo",
  },
  detailed_address: {
    property: {
      street_number: "Hišna št.",
      street: "Ulica",
      city: "Mesto",
      postalcode: "Poštna št.",
    },
  },
  log: {
    heading: "Log",
    show_message: "Pokaži sporočilo",
    property: {
      time: "Timestamp",
      userEmail: "Uporabnik",
      receiver: "Poslano na",
      citizen: "Občan",
      message: "Sporočilo",
    },
  },
  mapped_classification: {
    label: "Klasifikacija",
    save: "Shrani",
    AUTOMATICALLY: "Samodejno",
    MANUALLY: "Ročno",
    ORIGINAL: "Privzeto {{originalValue}}",
  },
  verify_communication_channel: {
    verified_successfully: "Ta komunikacijski kanal je bil uspešno preverjen.",
    to_login: "Prijava",
  },
}
