import { gql } from "apollo-boost"
import { sendTestMessagesToChannel, sendTestMessagesToChannelVariables } from "../generated/sendTestMessagesToChannel"

export const SEND_TEST_MESSAGES_TO_CHANNEL_MUTATION = gql`
  mutation sendTestMessagesToChannel(
    $channelType: CommunicationChannelType!
    $communicationChannelReceiver: [String!]!
    $messages: [String!]!
  ) {
    sendTestMessagesToChannel(channelType: $channelType, communicationChannelReceiver: $communicationChannelReceiver, messages: $messages) {
      totalReceivers
      ratingLowReceivers
      ratingMediumReceivers
      ratingHighReceivers
      ratingUnknownReceivers
      sentToReceivers
      errorCount
      errorCode
      errorMessage
    }
  }
`

export type SendTestMessagesToChannelResult = sendTestMessagesToChannel
export type SendTestMessagesToChannelVariables = sendTestMessagesToChannelVariables