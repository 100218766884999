import {
  MaterialAnalysisSource,
  ErrorTypes,
  WarningTypes,
  MappedClassification,
  ChangePasswordError,
  ExportRfidGroupsError,
  RfidChipType,
} from "./../../api/graphql/generated/globalTypes"
import {
  AbilityName,
  InvalidityType,
  Rating,
  TextPlaceholderKey,
  WER_ERROR_CODE,
  Frequency,
  ClassificationSelector,
  TourType,
} from "../../api/graphql/generated/globalTypes"
import { AdvancementPDFButtonStatus } from "../../components/partials/pdf/AdvancementPDFButton"
import { MaterialDistributionEnum } from "../../constants/Constants"

export const en = {
  admin: "admin",
  denovo: "Denovo",
  language: "Language",
  request_timeout: "The network request timed out",
  request_failed: "The network request failed",
  privacy_policy_link: "https://www.ecotec-scantec.com/data-security",
  terms_and_conditions_link:
    "https://afb96ab4-d9ff-4610-86b4-d36fd6d3650e.usrfiles.com/ugd/afb96a_8e4027a8de434bd5abe0345f62c670bb.pdf",
  languages: {
    de: "Deutsch",
    en: "English",
    sl: "Slovenščina",
  },
  tour_types: {
    [TourType.organic]: "Bio waste",
    [TourType.residual]: "Residual waste",
  },
  menu: {
    dashboard: "Dashboard",
    analysis: "Analysis",
    pdf: "PDF Creation",
    communication_center: "Kommunikationscenter",
    tours: "Tours",
    association_management: "Associations & Towns",
    housing_managements_management: "Housing Managements",
    rfid_management: "RFIDs",
    citizen_management: "Citizens",
    user_management: "Users",
    vehicle_management: "Vehicles",
    login: "Log-In",
    text_management: "Texts",
    statistics: "Statistics",
    eda: "EDA",
    log: "Log",
    file_templates: "File Templates",
    rfid_groups: "RFID Groups per house",
  },
  login_page: {
    email: "Email",
    password: "Password",
    login: "Log-In",
    continue: "Continue",
    data_privacy_statement: "Data Privacy Statement",
    terms_and_conditions: "Terms And Conditions",
    imprint: "imprint",
    error_title: "Change your entry",
    forgot_password: "Password forgotten?",
    password_reset_error: "Password reset failed",
    password_reset_success: "Password mail was sent",
    password_reset_dialog: {
      title: "Password forgotten",
      cancel: "Cancel",
      confirm: "Reset password",
    },
  },
  reports_page: {
    heading: "Reports",
  },
  tour_page: {
    create_tour: "Create tour",
    heading: "Tour planning",
    import_tours: "Import tours",
    errors: {
      duplicate: "Tour is already existing",
      endtime_before_starttime: "Starttime has to be before endtime.",
    },
    warnings: {
      overlapping_entry_date:
        "There is already an existing tour with the same data: association, town, fraction, vehicle and date.",
      overlapping_entry_date_time: "Tour times are overlapping with another tour.",
    },
    import_rfid_readings: "Import RFID readings",
    import_tour_readings_dialog: {
      title: "Import RFID Readings",
      association_label: "Association",
      town_label: "Town",
      import: "Import",
      import_failed: "Import failed:",
      invalid_rfids: " - Following RFIDs are not registered in the selected town: '{{invalidRfids}}'",
      invalid_wastescanner_ids: " - Following vehicles are invalid: '{{invalidWastescannerIds}}'",
      invalid_format: "- Check headers and data of the file.",
      success:
        "Import successful: {{countImported}} RFID readings were imported, {{countDuplicates}} were skipped (duplicates)",
      close: "Close",
      daheim_info: "RFID readings can only be imported into Daheim towns.",
    },
  },
  dashboard_page: {
    heading: "Dashboard",
    tour_section: {
      heading: "Tours",
      no_data: "There are no tours scheduled this week!",
    },
    vehicle_section: {
      //TODO: ADD KW to the beginning once there is enough data
      heading: "LKWs",
      no_data: "No Trucks found!",
    },
  },
  analysis_page: {
    heading: "Wastescanner Analysis",
    no_data_for_this_filter: "There is no data for this filter",
    no_data_for_housing_management: "No data found for your housing management.",
    filter_classification_default: "Standard",
    filter_classification_auto: "Only automatic",
    filter_classification_manually: "Only manually",
    composition_heading: "Composition of {{type}} - filtered section",
    quality_of_seperation: "Quality of seperation",
    pictures: "Pictures",
    invalid_pictures: "Unclassifyable Pictures",
    adjusted_date_to_emptying: "adjusted date to emptying week",
    analysis_could_not_be_deleted: "Analysis could not be deleted",
    no_available_associations: "There are no Associations or Towns assigned to you.",
    filter: {
      association: "Association",
      town: "Town",
      empty_week: "Emptying week",
      empty_week_last_10_days: "last 10 days",
      waste_type: "Waste type",
      percent_of: "Percent from",
      percent_to: "Percent to",
      period_from: "Period from",
      period_to: "Period to",
      classification: "Classification",
      rating: "Rating",
      from: "From",
      to: "To",
      waste_scanner: "WasteScanner",
      source: "Source",
      rfids: "RFIDs",
    },
    list: {
      classification: "Classification",
      bag_amount: "Bags",
      bag_amount_description: "is treated like loose trash",
    },
    classificationSelector: {
      [ClassificationSelector.ALL]: "ALL",
      [ClassificationSelector.STANDARD]: "Standard",
      [ClassificationSelector.MANUAL]: "Only Manual",
      [ClassificationSelector.AUTOMATIC]: "Only Automatic",
    },
    fractions: {
      miss_sortings: "Miss sortings",
      not_visible: "Not visible",
      [MaterialDistributionEnum.Residual]: "Residual waste",
      [MaterialDistributionEnum.Organic]: "Organic waste",
      [MaterialDistributionEnum.Battery]: "Battery",
      [MaterialDistributionEnum.BlownBottle]: "Hollow body",
      [MaterialDistributionEnum.Glass]: "Glass",
      [MaterialDistributionEnum.Hazardous]: "Hazardous Waste",
      [MaterialDistributionEnum.Metal]: "Metal",
      [MaterialDistributionEnum.PET]: "PET",
      [MaterialDistributionEnum.Paper]: "Paper",
      [MaterialDistributionEnum.Spraycan]: "Aerosol cans",
      [MaterialDistributionEnum.Plastic]: "Plastic",
      [MaterialDistributionEnum.PlasticOther]: "Light plastic",
      [MaterialDistributionEnum.GarbageBagOther]: "Other garbage bag",
      [MaterialDistributionEnum.GarbageBagTransp]: "Transparent garbage bag",
      [MaterialDistributionEnum.GarbageBagYellow]: "yellow garbage bag",
      [MaterialDistributionEnum.GarbageBagOrganic]: "Biodegradable bag",
    },
    seperation_quality_history: "Seperation quality history",
    history: "History",
    miss_sortings_quota: "Miss sortings quota",
    tout_type_title_quota: "{{type}}-quota",
    filter_tourtype_name: "Main Fraction",
    filter_tourtype_residual: "Residual waste",
    filter_tourtype_organic: "Bio waste",
    advancement_pdf: {
      section_title: "PDF Export",
      number_of_images: "Number of images {{numberOfItems}}",
      disclaimer: "{{numberOfItems}} images found for this filter.",
      terms_and_condition:
        "It is the responsibility of the user to comply with the existing data protection regulations.",
      gps_accuracy: "Before proceeding, we would like to point out that the GPS resolution may be inaccurate.",
      button: {
        [AdvancementPDFButtonStatus.creatingPdf]: "is creating...",
        [AdvancementPDFButtonStatus.failed]: "Failed",
        [AdvancementPDFButtonStatus.notStarted]: "Create",
        [AdvancementPDFButtonStatus.readyToDownload]: "Download",
      },
      menu: {
        create_zip: "create ZIP",
        create_pdf: "create PDF",
      },
      tooltip_loading: "please wait",
      ratings_error: "choose at least one rating",
      timespan_info: "It's recommended to select a timespan from 2 to 3 weeks at maximum.",
    },
    waste_quality: {
      heading: "Waste Quality",
      num_of_invalid: "Number of Invalid Images: {{count}}",
    },
  },
  general: {
    show_more: "show more",
    main_fraction: "Main Fraction",
    missthrow: "Missthrow",
    waste: "Waste",
    send: "Send",
    test: "Test",
    add: "Add",
    wastescanner: "Wastescanner",
    wastescanner_portal: "Wastescanner portal",
    logout: "Log out",
    cancel: "Cancel",
    data_loading: "Data is loading...",
    details: "Details",
    towns: "Towns",
    latitude: "Latitude",
    longitude: "Longitude",
    radius: "Radius",
    edit: "Edit",
    delete: "Delete",
    remove: "Remove",
    create: "Create",
    daheim_town: "Daheim town",
    symvaro_town: "Symvaro town",
    search: "Search",
    reports: "Reports",
    associations: "Associations",
    associations_and_towns: "Associations & Towns",
    no_data_available_reload_page: "An error occured, please reload the page",
    created_at: "Created at {{date}}",
    category: "Category",
    rating: "Rating",
    loading: "Loading",
    download: "Download",
    error: "Error",
    excel_export: "Excel export",
    CW: "CW",
    to: "to",
    convert: "Convert",
    duplicate: "Duplicate",
    success: "Success",
    save: "Save",
    confirm: "Confirm",
    diagram: "Diagram",
    apply: "Apply",
    please_choose: "Please choose...",
    no: "No",
    yes: "Yes",
    ok: "OK",
    manually: "manually",
    custom: "Custom",
    default: "Default",
    yes_delete: "Yes, delete",
    list_loading: "List is loading...",
    please_wait: "Please be patient",
    to_few_data_diagram: "Too little date to show a diagram .\n",
    please_change_filter: " Please change the filter.",
    bookmark: "Bookmark",
    bookmarks: "Bookmarks",
    name: "Name",
    edited: "edited",
    channel: "Channel",
    reupload: "Upload again",
    close: "close",
    all: "All",
    trucks: "Trucks",
    stats: "Statistic",
    clear: "Clear",
    rating_schema: "Rating Schema",
    day: "day",
    day_plural: "days",
    housing_management: "Housing Management",
  },
  bookmark: {
    save_bookmark: "Save Bookmark",
    save_new_bookmark: "Save as new Bookmark",
    delete_bookmark: "Delete Bookmark",
    create_bookmark: "Create Bookmark",
    successfully_created: "Bookmark was successfully created",
    successfully_updated: "Bookmark was saved",
    no_bookmarks_found: "There weren't any bookmarks created yet!",
  },
  communication_channels: {
    daheim_app: "Daheim App",
    symvaro: "Symvaro",
    newspaper: "Newspaper",
    black_board: "Notice board",
    sms: "SMS",
    new_component: "New component",
    choose_component: "Choose component",
    email: "Email",
  },
  table: {
    no_data: "No data available",
    previous: "Previous",
    next: "Next",
    page: "Page",
    rows: "Rows",
    of: "of",
  },
  analysis_detail: {
    title: "Fractions",
    image_information: "Image Information",
    more: "Normal",
    less: "Fractions",
    invalid_image_title: "Invalid Image:",
    date_time: "Date / Time",
    image_number: "Image number",
    rfid_number: "RFID number",
    delete_dialog: {
      heading: "Delete analysis",
      text: "Do you want to delete this analysis?",
    },
    invalidate_analysis: {
      title: "Invalidate analysis",
      reason: "Reason",
      invalidate: "Invalidate",
      cancel: "Cancel",
    },
  },
  tour: {
    types: {
      residual: "Residual waste",
      organic: "Organic waste",
    },
    property: {
      town: "Towns",
      association: "Association",
      tourType: "Fraction",
      date: "Date",
      startTime: "Start",
      endTime: "End",
      wasteScanner: "Waste scanner",
      rfidReader: "RFID reader",
      geocept_device: "GeoCept device (IMEI)",
      is_splitted: "3-Image-Analysis",
      timePeriod: "Time",
    },
    tableColumn: {
      analysis_count: "# analysis",
      analysis_count_split_all: "# Analysis (all)",
      analysis_count_valid: "# Analysis (valid)",
      analysis_has_rfid_readings: "Rfid readings",
      action: "Edit",
    },
    dialog: {
      create: {
        title: "Create new tour",
        button: "Create",
      },
      create_anyway: {
        title: "Create anyway?",
        button: "Create anyway",
      },
      update: {
        title: "Edit tour",
        button: "Save",
        time_info: "The tour has to start before {{min}} and has to end after {{max}}.",
      },
      update_anyway: {
        title: "Save anyway?",
        button: "Save anyway",
      },
      delete: {
        button: "Delete permanently",
      },
      delete_tour_data: {
        title: "Delete RFID readings & Material Analysis",
        title_deleted: "RFID Readings & Material Analysis were deleted successfully",
        text: "Do you really want to delete all RFID readings for this tour?",
        text_deleted_plural:
          "{{rfidReadingsCount}} Rfid reading(s) & {{materialAnalysisCount}} Material Analysis was successfully deleted.",
        button: "Delete",
        delete_material_analysis_too: "Delete images and analysis",
      },
    },
  },
  validation: {
    time: {
      notpossible: "Time is not possible",
    },
    date: {
      notpossible: "Date is not possible",
    },
  },
  text_management: {
    heading: "Text management",
    add_category: "Category",
    please_choose_category: "Please choose a category",
    add_template: "Add text template",
    no_templates_available: "No text templates available",
    freetext: "Freetext",
    placeholder: "Placeholder",
    text_editor: "Texteditor",
    preview: "Preview",
    default: "Default template",
    dialog: {
      create: {
        title: "Create text template",
        button: "Create",
      },
      update: {
        title: "Update text template",
        button: "Save",
      },
      delete: {
        heading: "Delete text template",
        text: "Do you want to delete this Template?",
        confirmText: "Delete",
      },
    },
  },
  text_placeholder: {
    [TextPlaceholderKey.NEW_LINE_SINGLE]: "Wordwrap",
    [TextPlaceholderKey.NEW_LINE_DOUBLE]: "Newline",
    [TextPlaceholderKey.TOUR_TYPE]: "Tour type",
    [TextPlaceholderKey.TOWN_NAME]: "Town",
    [TextPlaceholderKey.MAIN_MISSTHROW_1_NAME]: "Main missthrow material",
    [TextPlaceholderKey.MAIN_MISSTHROW_2_NAME]: "Second main missthrow material",
    [TextPlaceholderKey.MISSTHROW_PERCENTAGE]: "Missthrow percentage",
    [TextPlaceholderKey.MISSTHROW_PER_TOWN_PERCENTAGE]: "Missthrow percentage (Town)",
    [TextPlaceholderKey.INVALID_TOTAL_COUNT]: "Amount of unclassifyable",
    [TextPlaceholderKey.INVALID_REASON]: "Reason for unclassifyability",
    [TextPlaceholderKey.RFID]: "RFID(s)",
    [TextPlaceholderKey.MATERIAL_ANALYSIS_LIST]: "Emptyings (List)",
    preview: {
      [TextPlaceholderKey.NEW_LINE_SINGLE]: "\n",
      [TextPlaceholderKey.NEW_LINE_DOUBLE]: "\n\n",
      [TextPlaceholderKey.TOUR_TYPE]: "Restmüll",
      [TextPlaceholderKey.TOWN_NAME]: "Feldkirchen",
      [TextPlaceholderKey.MAIN_MISSTHROW_1_NAME]: "Plastic",
      [TextPlaceholderKey.MAIN_MISSTHROW_2_NAME]: "Glass",
      [TextPlaceholderKey.MISSTHROW_PERCENTAGE]: {
        [Rating.High]: "1",
        [Rating.Medium]: "14",
        [Rating.Low]: "50",
        [Rating.Unknown]: "14",
      },
      [TextPlaceholderKey.RFID]: "303030303",
      [TextPlaceholderKey.MATERIAL_ANALYSIS_LIST]:
        "Musterstraße 3, Musterdorf 0000 (12.12.2022)\nMusterstraße 6, Musterdorf 0000 (12.12.2022)",
      [TextPlaceholderKey.MISSTHROW_PER_TOWN_PERCENTAGE]: "14",
      [TextPlaceholderKey.INVALID_TOTAL_COUNT]: "5",
      [TextPlaceholderKey.INVALID_REASON]: "Es war nur Schokolade im Müll",
    },
  },
  association_management: {
    heading: "Association management",
    add_association: "Add association",
    add_town: "Add town",
    no_towns: "There are no towns in this association.",
    enable_custom_rating_schema: "Set Rating Schema manually",
    dialog: {
      create: {
        title: "Create association",
        button: "Create",
      },
      update: {
        title: "Save association",
        button: "Save",
      },
      delete: {
        button: "Delete permanently",
      },
    },
    town_dialog: {
      create: {
        title: "Create town",
        button: "Create",
      },
      update: {
        title: "Edit town",
        button: "Save",
      },
      delete: {
        button: "Delete permanently",
      },
      link_with_town_boundaries: "Link with geofence",
      link_with_town_boundaries_info: "Analysis will automatically linked with this town when uploaded.",
      create_as_daheim_town: "Connect with a Daheim town",
      only_manually_analysis: "Only show manually classified data to the users",
      label_info: "You can see all data as an administrator",
    },
  },
  housing_management: {
    heading: "Housing Managements",
    add_housing_management: "Add Housing Management",
    no_housing_managements: "No Housing Managements Found",
    towns: "Towns",
    associations: "Associations",

    rfids: {
      title: "RFIDs",
      button: "Show RFIDs",
    },
    dialog: {
      create: {
        title: "Create Housing Management",
        button: "Create",
      },
      update: {
        title: "Update Housing Management",
        button: "Save",
      },
      delete: {
        button: "Delete",
      },
      close: {
        button: "Close",
      },
    },
    property: {
      name: "Name",
    },
  },
  citizen_management: {
    heading: "Citizen management",
    no_citizens: "There are no citizens in this town.",
    dialog: {
      update: {
        title: "Edit citizen",
        button: "Save",
      },
      delete: {
        button: "Delete permanently",
      },
    },
    import_citizens: "Import citizens",
    import_citizens_dialog: {
      title: "Import Citizens",
      association_label: "Association",
      town_label: "Town",
      import: "Import",
      close: "Close",
      success: "Citizens imported successfully.",
      unknown_error: "An unknown error occured.",
      daheim_info: "Citizens can only be imported into Daheim towns.",
    },
  },
  user_management: {
    heading: "User management",
    add_user: "Add user",
    associations_and_towns: "Associations and Towns",
    housing_managements: "Housing Managements",
    vehicles: "Vehicles",
    assign_all_towns: "Assign to all towns",
    all_towns_assigned: "all towns",
    select_single_towns_info: "You can also assign Towns individually",
    set_town_as_default:
      "Set this town as default - this town will be preselected in the analysis tab on login for this user",
    dialog: {
      create: {
        title: "Create user",
        button: "Create",
      },
      update: {
        title: "Edit user",
        button: "Save",
      },
      delete: {
        button: "Delete permanently",
      },
      info_password_set: "Password already set.",
      info_password_not_set: "Password not set yet.",
      link_reset_password: "Send password recovery email to the user",
    },
    ability: {
      [AbilityName.admin]: "Admin",
      [AbilityName.manager]: "Manager",
      [AbilityName.tugraz]: "TU Graz",
      [AbilityName.area_manager]: "Area Admin",
      [AbilityName.advancement_pdf]: "PDF for ratings",
      [AbilityName.town_boundaries]: "Geofence",
      [AbilityName.housing_management]: "Housing Manager",
      [AbilityName.waste_composition]: "Missthrow Composition",
    },
  },
  town: {
    property: {
      name: "Town Name",
      latitude: "Latitude",
      longitude: "Longitude",
      radius: "Radius",
      zipCode: "Zip code",
      classification: "Classification",
      classification_auto: "automatic",
      classification_manually: "manual",
      classification_unset: "-",
    },
  },
  association: {
    property: {
      name: "Association",
      latitude: "Latitude",
      longitude: "Longitude",
      radius: "Radius",
      document_deletion_range_in_days: "Delete documents after",
    },
  },
  citizen: {
    property: {
      firstName: "Firstname",
      lastName: "Lastname",
      rfid: "RFID {{number}}",
      rfids: "RFIDs",
      code: "Code",
      address: "Address",
      phone1: "Tel. 1",
      phone2: "Tel. 2",
      email: "Email ",
    },
  },
  user: {
    property: {
      email: "Email",
      name: "Name",
      towns: "Towns",
      associations: "Associations",
      abilities: "Abilities",
      default_town: "Default Town",
    },
  },
  communication_center: {
    heading: "Communication center",
    no_reports: "No reports found.",
    overall_rating: "Overall rating",
    create_a_report_from_an_analysis: "Create the first report based on an analysis.",
    text_individual_widget: {
      dialog: {
        heading: "Individual Text",
        text_category: "Text category",
      },
    },
    last_sent: "last sent",
    no_history_entry: "not yet sent",
    last_post_failed: "An error occured at the latest attempt to send.",
  },
  post_to_daheim_dialog: {
    heading: "Publish to Daheim App Newsfeed",
    error: "An Error occured, the report could not be published!",
  },
  password_reset: {
    heading: "Reset password",
    new_password: "New password",
    new_password_again: "Confirm new password",
    set_password: "Set password",
    back_to: "Back to",
    login: "Log-In",
    accept_privacy_policy: "I have read and accepted the <1>privacy policy</1> and <3>terms and conditions</3>.",
  },
  api_error: {
    65616: "This RFID-Chip does not exists.",
    65622: "You don't have permission to perform this action!",
    1002: "Tour overlaps with another!",
    1011: "Rating schema is invalid",
    1020: "This communication channel could not be verified",
    2001: "The title is too long (max. 200 characters)",
    3001: "The link is not valid anymore!",
    8102: "{{entity}} already exists!",
    8202: "{{entity}} doesn't exist!",
    7601: "Unable to send SMS. Check the phone number",
    7603: "Unable to send email. Check the email address",
    change_password: {
      [ChangePasswordError.NOT_ENOUGH_DIFFERENCT_CHARACTERS]:
        "The password must contain at least one upper and one lower case letter, one number and one special character.",
      [ChangePasswordError.SAME_PASSWORD]: "You must not use the same password as before!",
      [ChangePasswordError.TOO_SHORT]: "You cannot reuse your current password.",
    },
    export_rfid_groups: {
      [ExportRfidGroupsError.NO_RFID_GROUPS_FOR_TOWN]: "There are no RFID groups in this town.",
    },
  },
  WER_ERROR_CODE: {
    [WER_ERROR_CODE.SUCCESS]: "Success",
    [WER_ERROR_CODE.OTHER]: "Unknown error",
    [WER_ERROR_CODE.ABILITY_MISSING]: "Ability missing",
    [WER_ERROR_CODE.SIGN_IN__EMAIL_UNKNOWN]: "Email is wrong, please try again",
    [WER_ERROR_CODE.SIGN_IN__WRONG_PASSWORD]: "Password is wrong, please try again",
    [WER_ERROR_CODE.USER__INVALID_INPUT]: "Input is invalid",
    [WER_ERROR_CODE.USER__EMAIL_EXISTS]: "Email already exists",
    [WER_ERROR_CODE.DUPLICATE]: "Duplicate Name.", // TODO: Proofread
  },
  waste_quality: {
    [Rating.Unknown]: "General",
    [Rating.Low]: "Low",
    [Rating.Medium]: "Medium",
    [Rating.High]: "High",
  },
  waste_quality_short: {
    [Rating.Unknown]: "U",
    [Rating.Low]: "L",
    [Rating.Medium]: "M",
    [Rating.High]: "H",
  },
  report: {
    create_report: "Create report",
    description_title: "Give the Report a title so you can find it later more easily.",
    title: "Title",
    delete: "Delete report",
    delete_description: "Do you really want to delete the report {{report}}?",
    free_text: "Free text",
    enter_free_text: "Enter free text",
    overall_rating: "Average Rating",
    widget_not_recognised: "Unknown Widget",
    transform_graph: "Transform Diagramm",
  },
  widget_type: {
    title_text: "Text",
    generic_title_text: "Title",
    title_pie_chart: "Pie chart",
    title_pie_chart_generic: "Diagram",
    title_pie_chart_waste_quality: "Pie chart waste quality",
    title_pie_chart_waste_quality_generic: "Diagram",
    title_history_chart: "History chart",
    title_history_chart_generic: "Diagram",
    title_individual_text: "Individual Text",
    title_trend_chart_generic: "Diagram",
    title_trend_chart: "Trend Diagram Town",
    title_chart_trend_chart_comparison: "Trend Diagram Association",
    title_trend_chart_comparison_generic: "Diagram",
    choose_text_title: "Choose Text",
    choose_text_info: "Please choose a text.",
  },
  graph: {
    percent: "%-ratio",
    trend: {
      heading: "Trend Diagram Town ({{townName}})",
      tooltip: {
        max: "Maximum",
        min: "Minimum",
        trend: "Trend",
        overall: "Average",
      },
    },
    comparison_trend: {
      heading: "Trend Diagram Association ({{associationName}})",
      tooltip: {
        max: "Maximum",
        min: "Minimum",
        trend: "Trend",
        overall: "Average",
      },
      select_towns: "Please select atleast one town. A maximum of three communities can be selected at the same time.",
    },
  },
  sms_dialog: {
    heading: "Send SMS",
    receiver: "Receiver",
    when_send: "When do you want to send the SMS?",
    date: "Date",
    time: "Time",
    warning_select_every_lane: "Please select a text of every lane!",
    amount_receiver: "Amount of receivers: {{amount}}",
    good_news_amount: "High Messages: {{amount}}",
    medium_news_amount: "Medium Messages: {{amount}}",
    bad_news_amount: "Low Messages: {{amount}}",
    general_news_amount: "General Messages: {{amount}}",
    success_message_amount: "Successfully sent {{amount}} message(s).",
    success_message: "Successfully sent message.",
    test_info_text: "The selected text is going to be sent unchagend to the selected phone number",
    test_info_texts:
      "The templates are replaced with random data from the database and afterwards they are sent to the selected phone number",
    rfid: "RFID",
    rfid_group: "RFID Group",
  },
  email_dialog: {
    heading: "Send Email",
    receiver: "Receiver",
    when_send: "When do you want to send the email?",
    date: "Date",
    time: "Time",
    warning_select_every_lane: "Please select a text of every lane!",
    amount_receiver: "Amount of receivers: {{amount}}",
    good_news_amount: "High Messages: {{amount}}",
    medium_news_amount: "Medium Messages: {{amount}}",
    bad_news_amount: "Low Messages: {{amount}}",
    general_news_amount: "General Messages: {{amount}}",
    success_message_amount: "Successfully sent {{amount}} message(s).",
    success_message: "Successfully sent message.",
    test_info_text: "The selected text is going to be sent unchanged to the selected email address",
    test_info_texts:
      "The templates are replaced with random data from the database and afterwards they are sent to the selected email address",
    rfid: "RFID",
    rfid_group: "RFID Group",
  },
  excel_export: {
    excel_export: "Excel Export",
    filter: "Filter",
    no_filter_set: "No filter set",
    main_fraction: "Main Fraction",
    wrong_fractions: "Faulty fraction",
    address_unknown: "Unknown",
    export_unmapped: "export with all waste categories",
    export_mapped: "export with aggregated waste categories",
    export: {
      organization: "Organisation",
      overall_rating: "Average Rating",
      wertstoff: "Waste",
      amount: "Amount",
      missthrow: "Missthrow",
      date: "Date",
      gps: "GPS",
      classification: "Classification",
      total_miss_sortings: "Total Missthrows",
      address: "Address",
      rfid: "RFID",
      file: "File",
      rating: "Rating",
      invalid: "Invalid",
    },
  },
  errors: {
    unknown: "Unknown error",
    generic1: "Oops, an error occured!",
    generic2: "This should not happen!",
    no_reports: "No reports",
    no_reports2: "Create the first report based on an analysis.",
    endtime_before_starttime: "Starttime has to be before endtime.",
  },
  import_info: {
    error_types: {
      [ErrorTypes.HEADER_INCORRECT]: "Please check the header line (first line) in the excel-sheet",
      [ErrorTypes.TIME_SPAN_INCORRECT]: "Line {{line}}:  Please check the times ({{date}} {{time1}}-{{time2}})",
      [ErrorTypes.TIME_FORMAT_INCORRECT]:
        "Line {{line}}: Please check the date/time specification ({{date}}, {{time1}}, {{time2}})",
      [ErrorTypes.ASSOCIATION_NOT_FOUND]: "Line {{line}}: Association ({{association}}) could not be found",
      [ErrorTypes.TOWN_NOT_FOUND]: "Line {{line}}: Town ({{town}}) could not be found",
      [ErrorTypes.FRACTION_NOT_FOUND]: "Line {{line}}: Fraction ({{fraction}}) could not be found",
      [ErrorTypes.DUPLICATE]: "Line {{line}}: Data already exists",
      [ErrorTypes.ENTRY_INCOMPLETE]: "Line {{line}}: has empty Columns",
      [ErrorTypes.WASTESCANNER_NOT_FOUND]: "Line {{line}}: Wastescanner ({{wastescanner}}) could not be found",
      [ErrorTypes.RFID_NOT_MATCHING_TOWN]: "Line {{line}}: RFID ({{rfid}}) is not registered in selected town",
      [ErrorTypes.RFID_NOT_FOUND]: "Line {{line}}: RFID ({{rfid}}) could not be found",
      [ErrorTypes.BOOLEAN_VALUE_INCORRECT]: "Line {{line}}: Value is invalid! (Ja/Nein)",
      [ErrorTypes.PREFERRED_COMMUNICATION_CHANNEL_VALUE_INCORRECT]:
        'Line {{line}}: value of preferred communication channel is invalid! (SMS/E-Mail or both separated by ",")',
      [ErrorTypes.NO_ACCESS_TO_VEHICLE]:
        "Line {{line}}: You don't have permission to assign this vehicle. ({{wasteScannerId}})",
    },
    warning_types: {
      [`${WarningTypes.OVERLAPPING_ENTRY}_1`]: "Line {{line}}: Data is overlapping with an entry in the database",
      [`${WarningTypes.OVERLAPPING_ENTRY}_2`]: "Line {{line}}: Data is overlapping with an entry in line {{line2}}",
      [WarningTypes.CODE_FOR_RFID_UPDATED]:
        'Line {{line}}: Code for RFID ({{rfid}}) was updated: "{{originalCode}}" --> "{{newCode}}"',
      [WarningTypes.RFID_ALREADY_EXISTS]: "Line {{line}}: RFID already exists and will be overwritten on import",
      [WarningTypes.RFID_NOT_FOUND]: "Line {{line}}: RFID ({{rfid}}) could not be found",
      [WarningTypes.RFID_IMPORTED_IN_OTHER_TOWN]: 'Line {{line}}: RFID ({{rfid}}) was imported to town "{{town}}".',
      [WarningTypes.RFID_FOUND_IN_OTHER_TOWN]: 'Line {{line}}: RFID ({{rfid}}) was found for town "{{town}}".',
      [WarningTypes.UNKNOWN_RFID_CHIP_TYPE]:
        'Line {{line}}: Container type ({{type}}) is invalid. Type "Other" will be used.',
    },
  },
  import_tours_dialog: {
    error_file_selector: "Please choose an Excel-file with a maximum size of 2 MB",
    move_files: "Drop files here or",
    select_files: "click to upload",
    drop_files: "",
    import_success_heading: "Import successful",
    success: "Imported {{number}} Tours.",
    error: "An error occured during the import.",
    import: "Import",
    import_info:
      "Please create vehicles before importing. Check if correct RFID reader (if existing) is provided as it will not be taken from the vehicle entry.",
    import_nevertheless: "Import nevertheless",

    file_rejected: "This file format is not supported.",
  },
  widget_text_categories: {
    rating_with_missthrow_percentage: "Rating with missthrow percentage",
    rating_with_main_missthrow: "Rating with main missthrow",
    black_board: "Notice board",
    compare_with_town: "Comparison of current filter with town",
  },
  vehicle_management: {
    heading: "Vehicle Management",
    create_vehicle: "Create Vehicle",
    dialog: {
      create: {
        title: "Create Vehicle",
        button: "Create",
      },
      update: {
        title: "Edit Vehicle",
        button: "Save",
      },
      delete: {
        button: "Delete permanently",
      },
    },
  },
  vehicle: {
    property: {
      wasteScannerId: "Wastescanner",
      rfidReader: "RFID reader",
      number_of_tours: "Number of Tours",
      geocept_device: "GeoCept device (IMEI)",
      rfidReadingToMaterialAnalysisOffset: {
        min: "min Latency RFID Reading - Analysis",
        max: "max Latency RFID Reading - Analysis",
      },
      geoCeptToMaterialAnalysisOffset: {
        min: "min Latency GeoCept - Analysis",
        max: "max Latency GeoCept - Analysis",
      },
      image_usage_info: "{{used}} of {{available}}",
      image_usage: "Image Usage",
      image_limit: "Image Limit",
    },
  },
  material_analysis: {
    invalidity_type: {
      [InvalidityType.GENERAL]: "An error occured: ({{reason}})",
      [InvalidityType.BAG_AMOUNT]: "The amount of bags is to high: >66%",
      [InvalidityType.BRIGHTNESS]: "Its to bright/dark",
      [InvalidityType.CURTAIN]: "The curtain hides the fractions",
      [InvalidityType.DUST]: "To much dust",
      [InvalidityType.PRESS]: "The press is in the image",
      [InvalidityType.NOT_INVALID]: "No problem",
      [InvalidityType.SPLIT_NOT_VALIDATED]: "Analysis is not yet verified",
      [InvalidityType.BY_USER]: "Analysis has been labeled as invalid manually. (Reason: {{reason}})",
    },
  },
  statistics: {
    heading: "Wastescanner Statistics",
    invalid_images_title: "Invalid images",
    overview: {
      text: {
        total: "{{total}} taken images",
        valid: "{{valid}} valid images",
        invalid: "{{invalid}} invalid images",
        refine_results: "Filter",
      },
    },
    excel_export: {
      file_name: "Wastescanner Statisitics",
    },
    diagram: {
      title: "Diagramm",
      total: "total",
      valid: "valid",
      invalid: "invalid",
    },
  },
  frequency: {
    [Frequency.daily]: "Daily",
    [Frequency.weekly]: "Weekly",
    [Frequency.monthly]: "Monthly",
  },
  pdf_page: {
    heading: "PDF Creation",
  },
  rfid_management: {
    heading: "RFID Management",
    import_rfids: "import RFIDs",
    export_rfids: "Export RFIDs",
    no_rfids: "There are no RFIDs available",
    dialog: {
      update: {
        title: "Update RFID",
      },
      delete: {
        title: "Delete RFID",
        content: "Are you sure?",
      },
    },
    import_dialog: {
      title: "Import RFIDs",
      close: "Close",
      import: "Import",
      import_anyway: "Import Anyway",
      success: "RFIDs were successfully imported!",
    },
    export_dialog: {
      title: "Export RFIDs",
      close: "Close",
      export: "Export",
      success: "RFIDs were successfully exported!",
      association_label: "Association",
      town_label: "Town",
      rfid_management: "RFID_Chips",
    },
    unassignable_addresses: {
      resolve: "Resolve",
      info: "There are {{numUnassignableAddresses}} unassigned addresses for this town.",
      dialog: {
        title: "Unassigned Addresses",
        export_addresses: "Export unassigned addresses",
      },
    },
    delete_error: {
      EXISTING_ANALYSIS: "RFID cannot be deleted. Material Analysis already exists!",
      EXISTING_CITIZENS_CONNECTION: "RFID cannot be deleted. Citizen connection already exists!",
      EXISTING_RFID_READING: "RFID cannot be deleted. RFID reading already exists!",
    },
  },
  unassignable_address: {
    property: {
      address: "Address",
      comment: "Comment",
    },
  },
  rfid: {
    property: {
      rfid: "RFID",
      address: "Address",
      code: "Code",
      uniquely_assignable: "uniquely assignable",
      citizens: "Citizens",
      type: "Container Type",
      comment: "Comment",
      types: {
        [RfidChipType.SA1100L]: "SA1100L",
        [RfidChipType.SA120L]: "SA120L",
        [RfidChipType.SA240L]: "SA240L",
        [RfidChipType.SA360L]: "SA360L",
        [RfidChipType.SA770L]: "SA770L",
        [RfidChipType.SA80L]: "SA80L",
        [RfidChipType.Sonstiges]: "Other{{comment}}",
      },
    },
  },
  rfid_groups: {
    heading: "RFID groups per house",
    export_rfid_groups: "Export RFID Groups",

    dialog: {
      create: {
        title: "Create RFID Group",
        create_button: "Create group",
        rfid_field: "Add RFID",
        undefined_address_info: "After entering a valid address, RFIDs can be added to this group.",
        invalid_address_info: "A RFID group for this address is already existing!",
      },
      update: {
        title: "Update RFID Group",
        update_button: "Update group",
      },
      delete: {
        title: "Delete RFID Group",
        content: "Do you really want to delete this RFID group?",
      },
      create_default: {
        title: "Create default RFID groups",
        content: "Do you really want to create default RFID groups per house with more than one RFID?",
        no_new_groups: "No new RFID groups were created for this town.",
      },
      export: {
        title: "Export RFID Groups",
        close: "Close",
        export: "Export",
        success: "RFID Groups were successfully exported!",
        association_label: "Association",
        town_label: "Town",
        file_name: "RFID_Groups",
      },
      rfid_collection_title: "Group RFIDs",
    },
    add_rfid_group_button: "Add RFID Group",
    create_default_rfid_groups_button: "Create default RFID groups",
    property: {
      address: "Address",
      rfids: "RFIDs",
      street: "Street",
      streetNumber: "Number",
      city: "City",
      postalcode: "Postal Code",
    },
    no_rfidgroups: "No RFID Groups available.",
  },
  material_analysis_source: {
    [MaterialAnalysisSource.geocept]: "GeoCept",
    [MaterialAnalysisSource.rfid]: "RFID",
    [MaterialAnalysisSource.other]: "Other",
  },
  log: {
    heading: "Log",
    show_message: "Nachricht anzeigen",
    filter: {
      date_from: "From",
      date_until: "To",
    },
    property: {
      time: "Timestamp",
      userEmail: "User",
      receiver: "Sent to",
      citizen: "Citizen",
      message: "Message",
    },
  },
  mapped_classification: {
    label: "Classification",
    save: "Save",
    [MappedClassification.AUTOMATICALLY]: "Automatically",
    [MappedClassification.MANUALLY]: "Manually",
    [MappedClassification.ORIGINAL]: "Default {{originalValue}}",
  },
  verify_communication_channel: {
    verified_successfully: "This communication channel was verified successfully.",
    to_login: "To Login",
  },
  fraction_assignment: {
    assign_fractions: "Assign Fractions",
    use_custom_fraction_mapping: "use custom fraction mapping",
    main_fraction: "Main Fraction",
  },
}
