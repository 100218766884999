import React, { FunctionComponent, Fragment, useState, useEffect, useCallback, useMemo } from "react"
import {
  Theme,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core"
import { CheckCircle } from "@material-ui/icons"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { Dropzone } from "../dropzone/Dropzone"
import { UploadedFile } from "../tour-management/import-tours/UploadedFile"
import { UserAndPermissionsAssociation } from "../../../api/graphql/query/permitted-associations-for-user"
import {
  IMPORT_CITIZENS_MUTATION,
  ImportCitizensResult,
  ImportCitizensVariables,
} from "../../../api/graphql/mutation/import-citizens"
import { useMutation } from "react-apollo"
import { ImportCheckResult } from "../tour-management/import-tours/ImportCheckResult"
import { ImportInfo } from "../tour-management/import-tours/ImportInfo"
import lodash from "lodash"
import { Info } from "@material-ui/icons"
import { COLORS } from "../../../theme/theme"

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    width: 800,
  },
  row: {
    marginBottom: theme.spacing(2),
  },
  checkIcon: {
    color: COLORS.lime,
    fontSize: 50,
    marginRight: theme.spacing(2),
  },
  loadingIndicator: {
    marginTop: theme.spacing(4),
  },
}))

interface IImportCitizensDialogProps {
  open: boolean
  onClose: () => void
  associations: UserAndPermissionsAssociation[]
}

export const ImportCitizensDialog: FunctionComponent<IImportCitizensDialogProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { associations, open, onClose } = props

  const [uploadedFile, setUploadedFile] = useState<File | undefined>()
  const [selectedAssociation, setSelectedAssociation] = useState<UserAndPermissionsAssociation>()
  const [selectedTownId, setSelectedTownId] = useState<string>()
  const [validationData, setValidationData] = useState<ImportCitizensResult | undefined>()

  const [importCitizens, { loading: importLoading }] = useMutation<ImportCitizensResult, ImportCitizensVariables>(
    IMPORT_CITIZENS_MUTATION,
    {
      onError: () => toast.error(t("citizen_management.import_citizens_dialog.unknown_error")),
      onCompleted: setValidationData,
    },
  )

  const handleChangeAssociationId = useCallback(
    (associationId: string) => {
      const association = associations.find((association) => association.id === associationId)
      setSelectedAssociation(association)

      const townWithExternalTown = association && association.towns.find((town) => !!town.externalTown)

      if (townWithExternalTown) {
        setSelectedTownId(townWithExternalTown.id as string)
      } else {
        setSelectedTownId(undefined)
      }
    },
    [associations],
  )

  useEffect(() => {
    if (associations && associations.length > 0) {
      handleChangeAssociationId(associations[0].id)
    }
  }, [associations, handleChangeAssociationId])

  const importSuccessful = (): boolean => lodash.get(validationData, "importCitizens.isValid", false)

  const onRemoveUploadedFile = useCallback(() => {
    setUploadedFile(undefined)
    setValidationData(undefined)
  }, [setUploadedFile])

  const errorItems = useMemo(() => lodash.get(validationData, "importCitizens.errors", []) as ImportInfo[], [
    validationData,
  ])

  return (
    <Dialog maxWidth="xl" onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">{t("citizen_management.import_citizens_dialog.title")}</DialogTitle>
      <DialogContent className={classes.content}>
        {selectedAssociation && (
          <Grid container spacing={2} className={classes.row}>
            <Grid container item xs={12} spacing={1}>
              <Grid item>
                <Info />
              </Grid>
              <Grid item xs>
                <Typography>{t("citizen_management.import_citizens_dialog.daheim_info")}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="inputlabel-association">
                  {t("citizen_management.import_citizens_dialog.association_label")}
                </InputLabel>
                <Select
                  value={selectedAssociation.id}
                  onChange={(event) => handleChangeAssociationId(event.target.value as string)}
                  disabled={importLoading || importSuccessful()}
                >
                  {associations.map((association) => (
                    <MenuItem key={association.id} value={association.id}>
                      {association.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="inputlabel-town">
                  {t("citizen_management.import_citizens_dialog.town_label")}
                </InputLabel>
                <Select
                  value={selectedTownId || ""}
                  onChange={(event) => {
                    setSelectedTownId(event.target.value as string)
                  }}
                  disabled={importLoading || importSuccessful()}
                >
                  {selectedAssociation.towns.map((town) => (
                    <MenuItem key={town.id} value={town.id as string} disabled={!town.externalTown?.externalId}>
                      {town.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}
        {!uploadedFile && (
          <Dropzone
            accept="text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onFilesDropped={(files: File[]) => {
              setUploadedFile(files[0])
            }}
          />
        )}
        {uploadedFile && !importSuccessful() && (
          <Grid container justify="center" className={classes.row}>
            <UploadedFile disabled={importLoading} file={uploadedFile} onRemove={onRemoveUploadedFile} />
            {importLoading && <CircularProgress className={classes.loadingIndicator} />}
          </Grid>
        )}
        {uploadedFile &&
          (lodash.get(validationData, "importCitizens.errors", []).length > 0 ||
            lodash.get(validationData, "importCitizens.warnings", []).length > 0) && (
            <ImportCheckResult
              errors={errorItems}
              warnings={lodash.get(validationData, "importCitizens.warnings") as ImportInfo[]}
            />
          )}
        {importSuccessful() && (
          <Grid container justify="center" alignItems="center">
            <CheckCircle className={classes.checkIcon} />
            <Typography variant="h5">{t("citizen_management.import_citizens_dialog.success")}</Typography>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        {importSuccessful() ? (
          <Button color="primary" variant={"contained"} onClick={onClose}>
            {t("citizen_management.import_citizens_dialog.close")}
          </Button>
        ) : (
          <Fragment>
            <Button color="primary" onClick={onClose}>
              {t("general.cancel")}
            </Button>
            <Button
              disabled={importLoading || !selectedTownId || !uploadedFile || errorItems.length > 0}
              color="primary"
              variant={"contained"}
              onClick={() => {
                importCitizens({
                  variables: {
                    townId: selectedTownId as string,
                    file: uploadedFile as File,
                  },
                })
              }}
            >
              {t("citizen_management.import_citizens_dialog.import")}
            </Button>
          </Fragment>
        )}
      </DialogActions>
    </Dialog>
  )
}
