import React, { FunctionComponent, useEffect, useState } from "react"
import { Dialog, DialogContent, DialogTitle, Typography, DialogActions, Button, TextField } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useMutation } from "@apollo/react-hooks"
import { toast } from "react-toastify"
import { getGraphqlErrorCode } from "../../../../utils/get-graphql-error-code"
import lodash from "lodash"
import {
  SEND_TEST_MESSAGES_TO_CHANNEL_MUTATION,
  SendTestMessagesToChannelResult,
  SendTestMessagesToChannelVariables,
} from "../../../../api/graphql/mutation/send-test-messages"
import { ClipLoader } from "react-spinners"
import { ApolloError } from "apollo-client"
import { report_report_widgets } from "../../../../api/graphql/generated/report"
import { ChannelType, CommunicationChannelType } from "../../../../api/graphql/generated/globalTypes"
import {
  SEND_TEST_MESSAGE_FOR_WIDGET_TO_CHANNEL_MUTATION,
  SendTestMessageForWidgetToChannelResult,
  SendTestMessageForWidgetToChannelVariables,
} from "../../../../api/graphql/mutation/send-test-message-to-channel"

interface ITestSendToChannelDialogProps {
  open: boolean
  onClose: () => void
  infoText: string
  channelType: ChannelType
  textsToSend?: string[]
  reportChannelData?: report_report_widgets
}

export const TestSendToChannelDialog: FunctionComponent<ITestSendToChannelDialogProps> = (props) => {
  const { t } = useTranslation()
  const { open, onClose, infoText, channelType, textsToSend, reportChannelData } = props

  const [recipient, setRecipient] = useState("")
  const [readyToSend, setReadyToSend] = useState(false)

  const [sendDirectMutation, { loading: sendDirectMutationLoading }] = useMutation<
    SendTestMessagesToChannelResult,
    SendTestMessagesToChannelVariables
  >(SEND_TEST_MESSAGES_TO_CHANNEL_MUTATION, {
    onError,
    onCompleted,
  })

  const [sendTestMessageForWidgetMutation, { loading: sendTestMessageForWidgetLoading }] = useMutation<
    SendTestMessageForWidgetToChannelResult,
    SendTestMessageForWidgetToChannelVariables
  >(SEND_TEST_MESSAGE_FOR_WIDGET_TO_CHANNEL_MUTATION, {
    onError,
    onCompleted,
  })

  useEffect(() => {
    setReadyToSend(!lodash.isEmpty(recipient))
  }, [recipient])

  function sendMessage() {
    if (lodash.isArray(textsToSend)) {
      sendDirectMutation({
        variables: {
          communicationChannelReceiver: [recipient],
          messages: textsToSend,
          channelType: channelType === ChannelType.sms ? CommunicationChannelType.SMS : CommunicationChannelType.EMAIL,
        },
      }).then()
    } else if (!lodash.isNil(reportChannelData)) {
      sendTestMessageForWidgetMutation({
        variables: {
          widgetId: reportChannelData.id,
          channelType: channelType === ChannelType.sms ? CommunicationChannelType.SMS : CommunicationChannelType.EMAIL,
          communicationChannelReceiver: [recipient],
        },
      }).then()
    } else {
      toast.error(t(`No data to send`))
      console.error("error: ", "No data to send")
    }
  }

  function onError(error: ApolloError) {
    toast.error(t(`api_error.${getGraphqlErrorCode(error)}`))
    console.error("error: ", error)
  }

  function onCompleted(data: SendTestMessagesToChannelResult | SendTestMessageForWidgetToChannelResult) {
    let errorMessage
    let errorCode
    let smsSent
    if ("sendTestMessagesToChannel" in data) {
      errorMessage = data.sendTestMessagesToChannel?.errorMessage
      errorCode = data.sendTestMessagesToChannel?.errorCode
      smsSent = data.sendTestMessagesToChannel?.sentToReceivers
    } else if ("sendTestMessageForWidgetToChannel" in data) {
      errorMessage = data.sendTestMessageForWidgetToChannel?.errorMessage
      errorCode = data.sendTestMessageForWidgetToChannel?.errorCode
      smsSent = data.sendTestMessageForWidgetToChannel?.sentToReceivers
    }
    let errorText: string | null = null
    if (!lodash.isNil(errorCode) && errorCode > 0) {
      errorText = t(`api_error.${errorCode}`)
    } else if (lodash.isString(errorMessage) && lodash.isEmpty(errorMessage)) {
      errorText = errorMessage
    }
    if (lodash.isString(errorText)) {
      console.warn("error: ", errorText, errorCode, errorMessage)
      toast.error(errorText)
    } else {
      if (smsSent === 1) {
        toast.success(t(`${channelType}_dialog.success_message`))
      } else if ((smsSent ?? 0) > 1) {
        toast.success(t(`${channelType}_dialog.success_message_amount`, { amount: smsSent }))
      }
      onClose()
    }
  }

  const loading = sendDirectMutationLoading || sendTestMessageForWidgetLoading

  return (
    <Dialog open={open}>
      <form>
        <DialogTitle>{t(`${channelType}_dialog.heading`)}</DialogTitle>
        <DialogContent>
          <Typography>{infoText}</Typography>
          <TextField
            label={t(`${channelType}_dialog.receiver`)}
            fullWidth
            value={recipient}
            onChange={(event) => setRecipient(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color={"primary"} disabled={loading}>
            {t("general.cancel")}
          </Button>
          <Button
            onClick={(event) => {
              event.preventDefault()
              sendMessage()
            }}
            color={"primary"}
            disabled={!readyToSend || loading}
            type="submit"
          >
            {loading ? <ClipLoader color={"primary"} size={17} /> : t("general.send")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
