import { gql } from "apollo-boost"
import {
  sendCustomMessageToChannel,
  sendCustomMessageToChannelVariables,
} from "../generated/sendCustomMessageToChannel"

export const SEND_CUSTOM_MESSAGE_TO_CHANNEL_MUTATION = gql`
  mutation sendCustomMessageToChannel(
    $channelType: CommunicationChannelType!
    $message: String!
    $receivers: [String!]!
  ) {
    sendCustomMessageToChannel(channelType: $channelType, message: $message, receivers: $receivers) {
      totalReceivers
      ratingLowReceivers
      ratingMediumReceivers
      ratingHighReceivers
      ratingUnknownReceivers
      sentToReceivers
      errorCount
      errorCode
      errorMessage
    }
  }
`

export type SendCustomMessageToChannelResult = sendCustomMessageToChannel
export type SendCustomMessageToChannelVariables = sendCustomMessageToChannelVariables
