import React, { FunctionComponent, useEffect, useState } from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import CircularProgress from "@material-ui/core/CircularProgress"
import DialogTitle from "@material-ui/core/DialogTitle"
import SnackbarWrapper from "../../snackbar/SnackbarWrapper"
import moment, { Moment } from "moment"
import lodash from "lodash"
import { WertstoffDatePicker } from "../../controls/WertstoffDatePicker"
import WertstoffTimePicker from "../../controls/WertstoffTimePicker"
import { useTranslation } from "react-i18next"
import { Grid, Switch } from "@material-ui/core"
import { useLazyQuery, useMutation } from "@apollo/react-hooks"
import { Widget, WidgetFilter } from "../../../../api/graphql/query/report-detailed"
import { ChannelType, WidgetType, CommunicationChannelType } from "../../../../api/graphql/generated/globalTypes"
import { SimpleAlertDialog } from "./SimpleAlertDialog"
import { toast } from "react-toastify"
import {
  VALIDATE_COMMUNICATION_QUERY,
  ValidateCommunicationResult,
  ValidateCommunicationVariables,
} from "../../../../api/graphql/query/validate-communication"
import {
  SEND_TO_CHANNEL_MUTATION,
  SendToChannelResult,
  SendToChannelVariables,
} from "../../../../api/graphql/mutation/send-to-channel"
import { FilterHeading } from "../../report/FilterHeading"

interface ISendToChannelDialogProps {
  widgets: Widget[]
  open: boolean
  onClose: () => void
  filter: WidgetFilter
  refetchReportDetails: () => void
  channelType: ChannelType.sms | ChannelType.email
}

export const SendToChannelDialog: FunctionComponent<ISendToChannelDialogProps> = (props) => {
  const { t } = useTranslation()
  const { channelType, open } = props

  const [selectedDate, setSelectedDate] = useState<Moment>(moment())
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>()
  const [successMessage, setSuccessMessage] = useState<string | null>()
  const [useRfidGroups, setUseRfidGroups] = useState<boolean>(false)

  const widgetToSend: Widget | null | undefined = lodash.find(props.widgets, (widget) => {
    return widget.widgetType === WidgetType.text_individual && widget.channelType === channelType
  })

  const [sendToChannelMutation, { loading: mutationLoading }] = useMutation<
    SendToChannelResult,
    SendToChannelVariables
  >(SEND_TO_CHANNEL_MUTATION)

  const [validateQuery, { data: validationData }] = useLazyQuery<
    ValidateCommunicationResult,
    ValidateCommunicationVariables
  >(VALIDATE_COMMUNICATION_QUERY, { fetchPolicy: "network-only" })

  useEffect(() => {
    if (open && widgetToSend) {
      validateQuery({
        variables: {
          widgetId: widgetToSend?.id,
          channelType: channelType === ChannelType.sms ? CommunicationChannelType.SMS : CommunicationChannelType.EMAIL,
          useRfidGroups,
        },
      })
    }
  }, [widgetToSend, open])

  const handleClose = () => {
    if (props.onClose) props.onClose()
    setSelectedDate(moment())
  }

  const handleSendButton = () => {
    if (lodash.isNil(widgetToSend)) {
      setErrorMessage("Widget not set")
      return
    }

    setIsLoading(true)
    sendToChannelMutation({
      variables: {
        channelType: channelType === ChannelType.sms ? CommunicationChannelType.SMS : CommunicationChannelType.EMAIL,
        widgetId: String(widgetToSend?.id),
        useRfidGroups,
      },
    })
      .then((result) => {
        const resultData = result.data
        setSuccessMessage(
          t("sms_dialog.success_message_amount", {
            amount: resultData?.sendToChannel.sentToReceivers || 0,
          }),
        )
        if (resultData?.sendToChannel.errorCount) {
          setErrorMessage(resultData.sendToChannel.errorMessage)
        }
        setSelectedDate(moment())
      })
      .catch((reason) => {
        setErrorMessage(lodash.get(reason, "data.sendToChannel.errorMessage", t("errors.unknown")))
        console.error(reason)
        toast.error(t("post_to_daheim_dialog.error"))
      })
      .finally(() => {
        props.refetchReportDetails()
        setIsLoading(false)
      })
  }

  const handelDateTimePickerChange = (args: { target: { value: any } }) => {
    let newDate = lodash.get(args, "target.value")
    if (lodash.isNil(newDate)) {
      console.log("args.target.value is null:", args)
      return
    }
    console.log("args: ", newDate)
    setSelectedDate(newDate)
  }

  const onAlertClose = () => {
    setSuccessMessage(null)
    props.onClose()
  }

  const enabledTimeDelay = false // this disables the dummy interface for delayed sending of messages

  const validationObject = validationData?.validateCommunication

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby={`${channelType}_dialog_key`}>
      <DialogTitle id={`${channelType}_dialog_key`}>{t(`${channelType}_dialog.heading`)}</DialogTitle>
      <DialogContent>
        {lodash.isString(errorMessage) && (
          <SnackbarWrapper variant="error" message={errorMessage} style={{ marginBottom: "12px" }} />
        )}
        {lodash.isString(successMessage) && (
          <SimpleAlertDialog
            isOpen={lodash.isString(successMessage)}
            onClose={onAlertClose}
            title={t("general.success")}
            message={successMessage}
          />
        )}
        <Grid component="label" container alignItems="center" justify="flex-end" spacing={1}>
          <Grid item>{t(`${channelType}_dialog.rfid`)}</Grid>
          <Grid item>
            <Switch
              checked={useRfidGroups}
              color="primary"
              onChange={() => setUseRfidGroups(!useRfidGroups)}
              name="use-rfid-groups"
            />
          </Grid>
          <Grid item>{t(`${channelType}_dialog.rfid_group`)}</Grid>
        </Grid>
        <div style={{ marginTop: 8, marginBottom: 8 }}>
          {/* @ts-ignore */}
          <FilterHeading filter={props.filter} />
        </div>

        <DialogContentText variant={"h5"}>{t(`${channelType}_dialog.receiver`)}</DialogContentText>
        {lodash.isNil(validationObject) && <CircularProgress size={20} style={{ marginRight: "12px" }} />}
        {!lodash.isNil(validationObject) && (
          <div>
            <DialogContentText>
              {t(`${channelType}_dialog.amount_receiver`, {
                amount: validationObject.totalReceivers,
              })}
            </DialogContentText>
            <DialogContentText>
              {t(`${channelType}_dialog.good_news_amount`, {
                amount: validationObject.ratingHighReceivers,
              })}
            </DialogContentText>
            <DialogContentText>
              {t(`${channelType}_dialog.medium_news_amount`, {
                amount: validationObject.ratingMediumReceivers,
              })}
            </DialogContentText>
            <DialogContentText>
              {t(`${channelType}_dialog.bad_news_amount`, {
                amount: validationObject.ratingLowReceivers,
              })}
            </DialogContentText>
            <DialogContentText>
              {t(`${channelType}_dialog.general_news_amount`, {
                amount: validationObject.ratingUnknownReceivers,
              })}
            </DialogContentText>
          </div>
        )}
      </DialogContent>
      {enabledTimeDelay && (
        <DialogContent>
          <DialogContentText>{t(`${channelType}_dialog.when_send`)}</DialogContentText>
          <WertstoffDatePicker
            name={t(`${channelType}_dialog.date`)}
            minDate={moment().toISOString()}
            value={selectedDate.toISOString()}
            onChange={(value: any) => handelDateTimePickerChange(value)}
          />
          <WertstoffTimePicker
            name={t(`${channelType}_dialog.time`)}
            value={selectedDate.toISOString()}
            onChange={(value: any) => handelDateTimePickerChange(value)}
          />
        </DialogContent>
      )}
      <DialogActions>
        <Button disabled={false} onClick={handleClose} color="primary">
          {t("general.cancel")}
        </Button>
        <Button
          variant="contained"
          disabled={isLoading || mutationLoading || !validationObject?.totalReceivers}
          onClick={handleSendButton}
          color="primary"
        >
          {(isLoading || mutationLoading) && <CircularProgress size={20} style={{ marginRight: "12px" }} />}
          {t("general.send")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
